import { LocalStorageKeys, ProductInformation } from '@app/modules/shared/utils/utils';
import { GetAuthenticatedSessionDataResult } from '../swagger-api';
import { ProviderProduct } from './provider-product';

export enum BusinessModel {
  GENERAL = 0,
  B2C = 1,
  B2B = 2
}

export class User {
  public id: number;
  public firstName: string;
  public lastName: string;
  public token: string;
  public activationDate: number;
  public licenseKey: string;
  public licenseExpirationDate: number;
  public hasOnNetSecurity: boolean;
  public hasOnDeviceSecurity: boolean;
  public providerProduct: ProviderProduct;
  public businessModel: BusinessModel;
  public transparentData?: { [key: string]: string };

  constructor(
    id: number,
    firstName: string,
    lastName: string,
    token: string,
    activationDate: number,
    licenseKey: string,
    licenseExpirationDate: number,
    hasOnNetSecurity: boolean,
    hasOnDeviceSecurity: boolean,
    providerProduct: ProviderProduct,
    businessModel: BusinessModel,
    transparentData?: { [key: string]: string }
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.token = token;
    this.activationDate = activationDate;
    this.licenseKey = licenseKey;
    this.licenseExpirationDate = licenseExpirationDate;
    this.hasOnNetSecurity = hasOnNetSecurity;
    this.hasOnDeviceSecurity = hasOnDeviceSecurity;
    this.providerProduct = providerProduct;
    this.businessModel = businessModel;
    this.transparentData = transparentData;
  }

  public static fromAuthResult(authResult: GetAuthenticatedSessionDataResult): User {
    let hasOnDeviceSecurity = false;
    let hasOnNetSecurity = false;
    const productCode = authResult.providerProduct.cmsProductCode;

    if (
      productCode === ProductInformation.PRODUCT_ONNET_WITH_ENDPOINT_SECURITY ||
      productCode === ProductInformation.PRODUCT_ONNET_ONLY
    ) {
      hasOnNetSecurity = true;
    }

    if (
      productCode === ProductInformation.PRODUCT_ONNET_WITH_ENDPOINT_SECURITY ||
      productCode === ProductInformation.PRODUCT_ENDPOINT_ONLY
    ) {
      hasOnDeviceSecurity = true;
    }

    const sessionUser = authResult.sessionUser;
    const providerProduct = ProviderProduct.fromDTO(authResult.providerProduct);

    return new User(
      sessionUser.user.userIds.cmsUserId,
      sessionUser.user.firstName,
      sessionUser.user.lastName,
      localStorage.getItem(LocalStorageKeys.TOKEN),
      0,
      '',
      0,
      hasOnNetSecurity,
      hasOnDeviceSecurity,
      providerProduct,
      providerProduct.businessModel,
      authResult.transparentData
    );
  }
}
