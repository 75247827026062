import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdministrativeService, NotificationCenterItem } from '../swagger-api';

@Injectable()
export class NotificationService {
  constructor(private administrativeService: AdministrativeService) {}

  public get$(): Observable<NotificationCenterItem[]> {
    return this.administrativeService
      .notificationCenterUsingGET(undefined, undefined, true)
      .pipe(map(notificationCenterResult => notificationCenterResult.items));
  }

  public getWithoutDevice$(): Observable<NotificationCenterItem[]> {
    return this.administrativeService
      .notificationCenterUsingGET(undefined, undefined, false)
      .pipe(map(notificationCenterResult => notificationCenterResult.items));
  }
}
