import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const dashboardModule = () =>
  import('./modules/dashboard/dashboard.module').then(x => x.DashboardModule);
const profileModule = () => import('./modules/profile/profile.module').then(x => x.ProfileModule);
const manageExceptionModule = () =>
  import('./modules/manage-exception/manage-exception.module').then(x => x.ManageExceptionModule);
const deviceModule = () => import('./modules/device/device.module').then(x => x.DeviceModule);

const routes: Routes = [
  { path: '', loadChildren: dashboardModule },
  { path: 'profiles', loadChildren: profileModule },
  { path: 'manageexception', loadChildren: manageExceptionModule },
  { path: 'devices', loadChildren: deviceModule },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
