import { Injectable } from '@angular/core';
import { Observable as Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { User } from '@app/modules/core/models';
import { SSOUtil } from '@app/modules/shared/utils/sso.util';
import {
  AdministrativeService,
  AuthenticationService,
  OneTimePasswordRequestInput,
  OneTimePasswordRequestResult,
  OneTimePasswordGetResult,
  SessionControllerService
} from '../swagger-api';
import { LocalStorageKeys } from '@app/modules/shared/utils/utils';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(
    private sessionControllerService: SessionControllerService,
    private administrativeService: AdministrativeService,
    private authenticationService: AuthenticationService
  ) {}

  private handleAuhenticationError(error: any): void {
    // log error before redirection so that client side errors won't go unnoticed #FRONT-2402
    // eslint-disable-next-line no-console
    console.error('Error during authenticated session data fetching/mapping: ', error);
    SSOUtil.redirectToSSO();
  }

  public getUser$(): Observable<User> {
    return this.sessionControllerService.getAuthenticatedSessionDataUsingGET().pipe(
      map(authResult => User.fromAuthResult(authResult)),
      catchError(error => {
        this.handleAuhenticationError(error);
        return of(error);
      })
    );
  }

  public logout(changeAccount: boolean): void {
    const transparentDataWrapper = {
      transparentData: { changeAccount: changeAccount }
    };

    this.authenticationService
      .logoutWebUserUsingPOST(transparentDataWrapper as any)
      .pipe(
        tap(res => {
          if (res.resultCode === 'OK' && res.logoutUrl) {
            localStorage.removeItem(LocalStorageKeys.TOKEN);
            window.location.href = res.logoutUrl;
          } else {
            localStorage.removeItem(LocalStorageKeys.TOKEN);
            SSOUtil.redirectToSSO();
          }
        })
      )
      .subscribe();
  }

  public updateToken$(): Observable<string> {
    return this.sessionControllerService.getUpdatedJwtTokenDataUsingPUT().pipe(
      map(result => result.jwt),
      tap(jwt => localStorage.setItem(LocalStorageKeys.TOKEN, jwt))
    );
  }

  public requestOnTimePassword$(deviceId: number): Observable<OneTimePasswordRequestResult> {
    const OTPInput = this.getOTPInputFromDeviceId(deviceId);
    return this.administrativeService.requestOneTimePasswordUsingPOST(OTPInput);
  }

  public getExistingOneTimePassword$(
    actionSignature: string,
    cyanApplication: number,
    deviceId: number
  ): Observable<OneTimePasswordGetResult> {
    return this.administrativeService.getOneTimePasswordUsingGET(
      actionSignature,
      cyanApplication,
      deviceId
    );
  }

  private getOTPInputFromDeviceId(deviceId: number): OneTimePasswordRequestInput {
    return {
      deviceId,
      cyanApplication: 3,
      actionSignature: 'login',
      initialPassword: true,
      strongPassword: true
    };
  }
}
