const API_URL = 'ANSIBLE_API_URL';

export const environment = {
  production: true,
  launcherUrl: 'ANSIBLE_LAUNCHER_URL',
  apiUrl: API_URL,
  basePath: API_URL + '/cloudsecurity/api',
  withConfigurator: false,
  withLogout: false,
  phishingCategory: 24
};
