import {
  DeviceWithProfileRequest,
  ProfileDefinitionDTO,
  ProfileDefinitionInputDTO
} from '@app/modules/core/swagger-api';
import { WorkingTime } from './working-time';

export const ChildProfileType = DeviceWithProfileRequest.ProfileTypeEnum;

export class ProfileDefinition {
  public id: number;
  public childProfile: boolean;
  public allowCategoryOverride: boolean;
  public name: string;
  public urlCategoryBlockMask: string;
  public youtubeCategoryBlockMask: string;
  public wlanSecurity: boolean;
  //public customUrls: ProfileDefinitionCustomUrl[];
  public filterActive: boolean;
  public defaultDefinition: boolean;
  public timetable: WorkingTime;
  public nightRest: WorkingTime;
  public internetBlocked: boolean;

  constructor(
    id: number,
    childProfile: boolean,
    allowCategoryOverride: boolean,
    name: string,
    urlCategoryBlockMask: string,
    youtubeCategoryBlockMask: string,
    wlanSecurity: boolean,
    //customUrls: ProfileDefinitionCustomUrl[],
    filterActive: boolean,
    defaultDefinition: boolean,
    timetable: WorkingTime,
    nightRest: WorkingTime,
    internetBlocked: boolean
  ) {
    this.id = id;
    this.childProfile = childProfile;
    this.allowCategoryOverride = allowCategoryOverride;
    this.name = name;
    this.urlCategoryBlockMask = urlCategoryBlockMask;
    this.youtubeCategoryBlockMask = youtubeCategoryBlockMask;
    this.wlanSecurity = wlanSecurity;
    this.filterActive = filterActive;
    this.defaultDefinition = defaultDefinition;
    this.timetable = timetable;
    this.nightRest = nightRest;
    this.internetBlocked = internetBlocked;
  }

  public static copy(profileDefinition: ProfileDefinition): ProfileDefinition {
    const timetable = new WorkingTime(
      profileDefinition.timetable?.timeFrom,
      profileDefinition.timetable?.timeTo,
      profileDefinition.timetable?.weekdays
    );

    const nightRest = new WorkingTime(
      profileDefinition.nightRest?.timeFrom,
      profileDefinition.nightRest?.timeTo,
      profileDefinition.nightRest?.weekdays
    );

    return new ProfileDefinition(
      profileDefinition.id,
      profileDefinition.childProfile,
      profileDefinition.allowCategoryOverride,
      profileDefinition.name,
      profileDefinition.urlCategoryBlockMask,
      profileDefinition.youtubeCategoryBlockMask,
      profileDefinition.wlanSecurity,
      profileDefinition.filterActive,
      profileDefinition.defaultDefinition,
      timetable,
      nightRest,
      profileDefinition.internetBlocked
    );
  }

  public static fromDTO(dto: ProfileDefinitionDTO): ProfileDefinition {
    return new ProfileDefinition(
      dto.id,
      dto.childProfile,
      dto.allowCategoryOverride,
      dto.name,
      dto.urlCategoryBlockMask,
      dto.youtubeCategoryBlockMask,
      dto.wlanSecurity,
      dto.filterActive,
      dto.defaultDefinition,
      WorkingTime.fromTimetable(dto.timetable),
      dto.childProfile
        ? WorkingTime.fromTimetable(dto.nightrest, '20:00', '08:00')
        : WorkingTime.fromTimetable(dto.nightrest),
      dto.fullLock
    );
  }

  public toInputDTO(): ProfileDefinitionInputDTO {
    return { profileDefinition: this.toDTO() } as ProfileDefinitionInputDTO;
  }

  private toDTO(): ProfileDefinitionDTO {
    return {
      allowCategoryOverride: this.allowCategoryOverride,
      defaultDefinition: this.defaultDefinition,
      filterActive: this.filterActive,
      id: this.id,
      childProfile: this.childProfile,
      name: this.name,
      urlCategoryBlockMask: this.urlCategoryBlockMask,
      wlanSecurity: this.wlanSecurity,
      youtubeCategoryBlockMask: this.youtubeCategoryBlockMask,
      timetable: this.timetable?.toTimetable(),
      nightrest: this.nightRest?.toTimetable(),
      fullLock: this.internetBlocked
    } as ProfileDefinitionDTO;
  }
}
