import { BlockedUrl, GetBlockingStatisticsResult } from '@app/modules/core/swagger-api';

export class BlockingStatistics {
  public blockedThreats: number;
  public blockedRequests: number;
  public unblockedThreats: number;
  public unblockedRequests: number;
  public tooManyOverrides: BlockedUrl[];

  constructor(
    blockedThreats: number,
    blockedRequests: number,
    unblockedThreats: number,
    unblockedRequests: number,
    tooManyOverrides: BlockedUrl[]
  ) {
    this.blockedThreats = blockedThreats;
    this.blockedRequests = blockedRequests;
    this.unblockedThreats = unblockedThreats;
    this.unblockedRequests = unblockedRequests;
    this.tooManyOverrides = tooManyOverrides;
  }

  public static fromDTO(dto: GetBlockingStatisticsResult): BlockingStatistics {
    return new BlockingStatistics(
      dto.blockedThreats,
      dto.blockedRequests,
      dto.unblockedThreats,
      dto.unblockedRequests,
      dto.tooManyOverrides
    );
  }
}
