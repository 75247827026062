/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeviceHardwareInfoDTO } from './deviceHardwareInfoDTO';
import { DeviceSecurityStatus } from './deviceSecurityStatus';
import { FilterChannelDTO } from './filterChannelDTO';
import { OperatingSystemInfo } from './operatingSystemInfo';
import { UserDTO } from './userDTO';

export interface DeviceDTO {
  deviceProtectionStatus?: DeviceDTO.DeviceProtectionStatusEnum;
  deviceSecurityStatus?: DeviceSecurityStatus;
  deviceType?: DeviceDTO.DeviceTypeEnum;
  deviceUser?: UserDTO;
  filterChannels?: Array<FilterChannelDTO>;
  hardware?: DeviceHardwareInfoDTO;
  id?: number;
  lineReference?: string;
  macAddress?: string;
  msisdn?: string;
  msisdn2?: string;
  name?: string;
  operatingSystemInfo?: OperatingSystemInfo;
  profileDefinitionId?: number;
  profileDefinitionName?: string;
}
export namespace DeviceDTO {
  export type DeviceProtectionStatusEnum =
    | 'NETWORK_PROTECTION_ACTIVE'
    | 'APPLICATION_PROTECTION_ACTIVE'
    | 'APPLICATION_PROTECTION_INACTIVE'
    | 'NETWORK_AND_APPLICATION_PROTECTION_INACTIVE'
    | 'NETWORK_AND_APPLICATION_PROTECTION_ACTIVE'
    | 'NETWORK_PROTECTION_ACTIVE_APPLICATION_PROTECTION_INACTIVE'
    | 'NETWORK_PROTECTION_INACTIVE_APPLICATION_PROTECTION_ACTIVE';
  export const DeviceProtectionStatusEnum = {
    NETWORKPROTECTIONACTIVE: 'NETWORK_PROTECTION_ACTIVE' as DeviceProtectionStatusEnum,
    APPLICATIONPROTECTIONACTIVE: 'APPLICATION_PROTECTION_ACTIVE' as DeviceProtectionStatusEnum,
    APPLICATIONPROTECTIONINACTIVE: 'APPLICATION_PROTECTION_INACTIVE' as DeviceProtectionStatusEnum,
    NETWORKANDAPPLICATIONPROTECTIONINACTIVE:
      'NETWORK_AND_APPLICATION_PROTECTION_INACTIVE' as DeviceProtectionStatusEnum,
    NETWORKANDAPPLICATIONPROTECTIONACTIVE:
      'NETWORK_AND_APPLICATION_PROTECTION_ACTIVE' as DeviceProtectionStatusEnum,
    NETWORKPROTECTIONACTIVEAPPLICATIONPROTECTIONINACTIVE:
      'NETWORK_PROTECTION_ACTIVE_APPLICATION_PROTECTION_INACTIVE' as DeviceProtectionStatusEnum,
    NETWORKPROTECTIONINACTIVEAPPLICATIONPROTECTIONACTIVE:
      'NETWORK_PROTECTION_INACTIVE_APPLICATION_PROTECTION_ACTIVE' as DeviceProtectionStatusEnum
  };
  export type DeviceTypeEnum =
    | 'ONNET'
    | 'MOBILE'
    | 'MOBILE_CHILD_PROTECTION'
    | 'ONNET_MOBILE_CHILD_PROTECTION'
    | 'WINDOWS';
  export const DeviceTypeEnum = {
    ONNET: 'ONNET' as DeviceTypeEnum,
    MOBILE: 'MOBILE' as DeviceTypeEnum,
    MOBILECHILDPROTECTION: 'MOBILE_CHILD_PROTECTION' as DeviceTypeEnum,
    ONNETMOBILECHILDPROTECTION: 'ONNET_MOBILE_CHILD_PROTECTION' as DeviceTypeEnum,
    WINDOWS: 'WINDOWS' as DeviceTypeEnum
  };
}
