/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { GetSystemConfigurationInputDTO } from '../model/getSystemConfigurationInputDTO';
import { GetSystemConfigurationResult } from '../model/getSystemConfigurationResult';
import { NotificationCenterResult } from '../model/notificationCenterResult';
import { OneTimePasswordGetResult } from '../model/oneTimePasswordGetResult';
import { OneTimePasswordRequestInput } from '../model/oneTimePasswordRequestInput';
import { OneTimePasswordRequestResult } from '../model/oneTimePasswordRequestResult';
import { PostMsisdnPinRequest } from '../model/postMsisdnPinRequest';
import { RequestOneTimePasswordResult } from '../model/requestOneTimePasswordResult';
import { SaveNotificationSettingsInputDTO } from '../model/saveNotificationSettingsInputDTO';
import { SaveNotificationSettingsResult } from '../model/saveNotificationSettingsResult';
import { SendMobileAppReminderResult } from '../model/sendMobileAppReminderResult';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class AdministrativeService {
  protected basePath = 'https://10.36.200.206:9021/cms-api-gateway/cloudsecurity/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Generate and send pin
   * Sms will be send only if provided msisdn belongs to the provider.
   * @param request request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public generateAndSendPinUsingPOST(
    request: PostMsisdnPinRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public generateAndSendPinUsingPOST(
    request: PostMsisdnPinRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public generateAndSendPinUsingPOST(
    request: PostMsisdnPinRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public generateAndSendPinUsingPOST(
    request: PostMsisdnPinRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (request === null || request === undefined) {
      throw new Error(
        'Required parameter request was null or undefined when calling generateAndSendPinUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/administrative/msisdn-pin`, request, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Get cyan one time password
   *
   * @param actionSignature action-signature
   * @param cyanApplication cyan-application
   * @param deviceId device-id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getOneTimePasswordUsingGET(
    actionSignature: string,
    cyanApplication: number,
    deviceId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OneTimePasswordGetResult>;
  public getOneTimePasswordUsingGET(
    actionSignature: string,
    cyanApplication: number,
    deviceId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OneTimePasswordGetResult>>;
  public getOneTimePasswordUsingGET(
    actionSignature: string,
    cyanApplication: number,
    deviceId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OneTimePasswordGetResult>>;
  public getOneTimePasswordUsingGET(
    actionSignature: string,
    cyanApplication: number,
    deviceId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (actionSignature === null || actionSignature === undefined) {
      throw new Error(
        'Required parameter actionSignature was null or undefined when calling getOneTimePasswordUsingGET.'
      );
    }

    if (cyanApplication === null || cyanApplication === undefined) {
      throw new Error(
        'Required parameter cyanApplication was null or undefined when calling getOneTimePasswordUsingGET.'
      );
    }

    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getOneTimePasswordUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (actionSignature !== undefined && actionSignature !== null) {
      queryParameters = queryParameters.set('action-signature', <any>actionSignature);
    }
    if (cyanApplication !== undefined && cyanApplication !== null) {
      queryParameters = queryParameters.set('cyan-application', <any>cyanApplication);
    }
    if (deviceId !== undefined && deviceId !== null) {
      queryParameters = queryParameters.set('device-id', <any>deviceId);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<OneTimePasswordGetResult>(`${this.basePath}/administrative/otp`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Get certain system configurations
   *
   * @param request request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getSystemConfigurationUsingPOST(
    request: GetSystemConfigurationInputDTO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GetSystemConfigurationResult>;
  public getSystemConfigurationUsingPOST(
    request: GetSystemConfigurationInputDTO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GetSystemConfigurationResult>>;
  public getSystemConfigurationUsingPOST(
    request: GetSystemConfigurationInputDTO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GetSystemConfigurationResult>>;
  public getSystemConfigurationUsingPOST(
    request: GetSystemConfigurationInputDTO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (request === null || request === undefined) {
      throw new Error(
        'Required parameter request was null or undefined when calling getSystemConfigurationUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<GetSystemConfigurationResult>(
      `${this.basePath}/administrative/get-sys-config`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Notification center
   *
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param includeDeviceNotifications include-device-notifications
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public notificationCenterUsingGET(
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    includeDeviceNotifications?: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<NotificationCenterResult>;
  public notificationCenterUsingGET(
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    includeDeviceNotifications?: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<NotificationCenterResult>>;
  public notificationCenterUsingGET(
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    includeDeviceNotifications?: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<NotificationCenterResult>>;
  public notificationCenterUsingGET(
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    includeDeviceNotifications?: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (includeDeviceNotifications !== undefined && includeDeviceNotifications !== null) {
      queryParameters = queryParameters.set(
        'include-device-notifications',
        <any>includeDeviceNotifications
      );
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<NotificationCenterResult>(
      `${this.basePath}/administrative/notification-center`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Request notification settings one time password
   *
   * @param notificationMsisdn notification-msisdn
   * @param subscriptionId subscription-id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestNotificationSettingsOTPUsingGET(
    notificationMsisdn: string,
    subscriptionId?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RequestOneTimePasswordResult>;
  public requestNotificationSettingsOTPUsingGET(
    notificationMsisdn: string,
    subscriptionId?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RequestOneTimePasswordResult>>;
  public requestNotificationSettingsOTPUsingGET(
    notificationMsisdn: string,
    subscriptionId?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RequestOneTimePasswordResult>>;
  public requestNotificationSettingsOTPUsingGET(
    notificationMsisdn: string,
    subscriptionId?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (notificationMsisdn === null || notificationMsisdn === undefined) {
      throw new Error(
        'Required parameter notificationMsisdn was null or undefined when calling requestNotificationSettingsOTPUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (notificationMsisdn !== undefined && notificationMsisdn !== null) {
      queryParameters = queryParameters.set('notification-msisdn', <any>notificationMsisdn);
    }
    if (subscriptionId !== undefined && subscriptionId !== null) {
      queryParameters = queryParameters.set('subscription-id', <any>subscriptionId);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<RequestOneTimePasswordResult>(
      `${this.basePath}/administrative/notification-settings/otp`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Request cyan one time password
   *
   * @param input input
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public requestOneTimePasswordUsingPOST(
    input: OneTimePasswordRequestInput,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<OneTimePasswordRequestResult>;
  public requestOneTimePasswordUsingPOST(
    input: OneTimePasswordRequestInput,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<OneTimePasswordRequestResult>>;
  public requestOneTimePasswordUsingPOST(
    input: OneTimePasswordRequestInput,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<OneTimePasswordRequestResult>>;
  public requestOneTimePasswordUsingPOST(
    input: OneTimePasswordRequestInput,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (input === null || input === undefined) {
      throw new Error(
        'Required parameter input was null or undefined when calling requestOneTimePasswordUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<OneTimePasswordRequestResult>(
      `${this.basePath}/administrative/otp`,
      input,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Save the notification settings
   *
   * @param request request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveNotificationSettingsUsingPOST(
    request: SaveNotificationSettingsInputDTO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SaveNotificationSettingsResult>;
  public saveNotificationSettingsUsingPOST(
    request: SaveNotificationSettingsInputDTO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SaveNotificationSettingsResult>>;
  public saveNotificationSettingsUsingPOST(
    request: SaveNotificationSettingsInputDTO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SaveNotificationSettingsResult>>;
  public saveNotificationSettingsUsingPOST(
    request: SaveNotificationSettingsInputDTO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (request === null || request === undefined) {
      throw new Error(
        'Required parameter request was null or undefined when calling saveNotificationSettingsUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<SaveNotificationSettingsResult>(
      `${this.basePath}/administrative/notification-settings`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Send a mobile reminder
   *
   * @param deviceId device-id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public sendMobileAppReminderUsingGET(
    deviceId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SendMobileAppReminderResult>;
  public sendMobileAppReminderUsingGET(
    deviceId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SendMobileAppReminderResult>>;
  public sendMobileAppReminderUsingGET(
    deviceId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SendMobileAppReminderResult>>;
  public sendMobileAppReminderUsingGET(
    deviceId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling sendMobileAppReminderUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (deviceId !== undefined && deviceId !== null) {
      queryParameters = queryParameters.set('device-id', <any>deviceId);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SendMobileAppReminderResult>(
      `${this.basePath}/administrative/mobile-app/reminder`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
