import { DomainExceptionDTO } from '../swagger-api';

export type DomainExceptionStatus = 'ALLOWED' | 'BLOCKED';
export const DomainExceptionStatus = {
  ALLOWED: 'ALLOWED' as DomainExceptionStatus,
  BLOCKED: 'BLOCKED' as DomainExceptionStatus
};

export class DomainException {
  public id: number;
  public categoryId: number;
  public domain: string;
  public status: DomainExceptionStatus;

  constructor(id: number, categoryId: number, domain: string, status: DomainExceptionStatus) {
    this.id = id;
    this.categoryId = categoryId;
    this.domain = domain;
    this.status = status;
  }

  public static fromDTO(dto: DomainExceptionDTO): DomainException {
    return new DomainException(dto.id, dto.categoryId, dto.domain, dto.blockingStatus);
  }

  public toDTO(): DomainExceptionDTO {
    return {
      id: this.id,
      categoryId: this.categoryId,
      domain: this.domain,
      blockingStatus: this.status
    } as DomainExceptionDTO;
  }
}
