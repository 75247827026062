import { environment } from '@environments/environment';

export class SSOUtil {
  /**
   * Redirect the page to the selfcare launcher (which will automatically redirect to SSO)
   *
   * @param returnUrl the desired return url after successful login, defaults to the current route
   */
  public static redirectToSSO(returnUrl: string = encodeURIComponent(window.location.href)): void {
    window.location.href = `${environment.launcherUrl}/api/login?returnUrl=${returnUrl}`;
  }
}
