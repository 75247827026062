import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private readonly closeSymbol = '✕';
  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}

  public success(message: string, bypassTranslate = false): void {
    this.alert(message, this.closeSymbol, 'success-snackbar', bypassTranslate);
  }

  public error(message: string, bypassTranslate = false): void {
    this.alert(message, this.closeSymbol, 'error-snackbar', bypassTranslate);
  }

  public toast(message: string, bypassTranslate = false): void {
    this.alert(message, undefined, 'success-snackbar', bypassTranslate);
  }

  private alert(
    message: string,
    action: string,
    panelClass: string,
    bypassTranslate: boolean
  ): void {
    const translatedMessage = bypassTranslate ? message : this.translate.instant(message);
    this.snackBar.open(translatedMessage, action, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: [panelClass]
    });
  }

  public clear(): void {
    this.snackBar.dismiss();
  }
}
