/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Object containing all properties required to create a new Device and a new ProfileDefinition
 */
export interface DeviceWithProfileRequest {
  deviceName: string;
  /**
   * Only {MOBILE_CHILD_PROTECTION, ONNET_MOBILE_CHILD_PROTECTION} are allowed
   */
  deviceType: DeviceWithProfileRequest.DeviceTypeEnum;
  msisdn?: string;
  pin?: string;
  profileName: string;
  profileType: DeviceWithProfileRequest.ProfileTypeEnum;
}
export namespace DeviceWithProfileRequest {
  export type DeviceTypeEnum = 'MOBILE_CHILD_PROTECTION' | 'ONNET_MOBILE_CHILD_PROTECTION';
  export const DeviceTypeEnum = {
    MOBILECHILDPROTECTION: 'MOBILE_CHILD_PROTECTION' as DeviceTypeEnum,
    ONNETMOBILECHILDPROTECTION: 'ONNET_MOBILE_CHILD_PROTECTION' as DeviceTypeEnum
  };
  export type ProfileTypeEnum = 'KID' | 'TEEN';
  export const ProfileTypeEnum = {
    KID: 'KID' as ProfileTypeEnum,
    TEEN: 'TEEN' as ProfileTypeEnum
  };
}
