/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { GetAntivirusStatisticsResult } from '../model/getAntivirusStatisticsResult';
import { GetBlockedUrlsResult } from '../model/getBlockedUrlsResult';
import { GetBlockingStatisticsResult } from '../model/getBlockingStatisticsResult';
import { GetDeviceOverviewStatisticsResult } from '../model/getDeviceOverviewStatisticsResult';
import { LoadSecurityDashboardDataResult } from '../model/loadSecurityDashboardDataResult';
import { LoadTLDsResult } from '../model/loadTLDsResult';
import { LoadThreatLevelHistoryResult } from '../model/loadThreatLevelHistoryResult';
import { LoadTrendsResult } from '../model/loadTrendsResult';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class StatisticsControllerService {
  protected basePath = 'https://10.36.200.206:9021/cms-api-gateway/cloudsecurity/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get antivirus statistics
   *
   * @param days days
   * @param includeAllManagedDevices include-all-managed-devices
   * @param deviceIdFilter device-id-filter
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAntivirusStatisticsUsingGET(
    days: number,
    includeAllManagedDevices: boolean,
    deviceIdFilter?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GetAntivirusStatisticsResult>;
  public getAntivirusStatisticsUsingGET(
    days: number,
    includeAllManagedDevices: boolean,
    deviceIdFilter?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GetAntivirusStatisticsResult>>;
  public getAntivirusStatisticsUsingGET(
    days: number,
    includeAllManagedDevices: boolean,
    deviceIdFilter?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GetAntivirusStatisticsResult>>;
  public getAntivirusStatisticsUsingGET(
    days: number,
    includeAllManagedDevices: boolean,
    deviceIdFilter?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (days === null || days === undefined) {
      throw new Error(
        'Required parameter days was null or undefined when calling getAntivirusStatisticsUsingGET.'
      );
    }

    if (includeAllManagedDevices === null || includeAllManagedDevices === undefined) {
      throw new Error(
        'Required parameter includeAllManagedDevices was null or undefined when calling getAntivirusStatisticsUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (days !== undefined && days !== null) {
      queryParameters = queryParameters.set('days', <any>days);
    }
    if (deviceIdFilter !== undefined && deviceIdFilter !== null) {
      queryParameters = queryParameters.set('device-id-filter', <any>deviceIdFilter);
    }
    if (includeAllManagedDevices !== undefined && includeAllManagedDevices !== null) {
      queryParameters = queryParameters.set(
        'include-all-managed-devices',
        <any>includeAllManagedDevices
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<GetAntivirusStatisticsResult>(
      `${this.basePath}/statistics/antivirus-statistics`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get blocked urls
   *
   * @param includeAllManagedDevices include-all-managed-devices
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param deviceIdFilter device-id-filter
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBlockedUrlsUsingGET(
    includeAllManagedDevices: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    deviceIdFilter?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GetBlockedUrlsResult>;
  public getBlockedUrlsUsingGET(
    includeAllManagedDevices: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    deviceIdFilter?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GetBlockedUrlsResult>>;
  public getBlockedUrlsUsingGET(
    includeAllManagedDevices: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    deviceIdFilter?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GetBlockedUrlsResult>>;
  public getBlockedUrlsUsingGET(
    includeAllManagedDevices: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    deviceIdFilter?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (includeAllManagedDevices === null || includeAllManagedDevices === undefined) {
      throw new Error(
        'Required parameter includeAllManagedDevices was null or undefined when calling getBlockedUrlsUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (deviceIdFilter !== undefined && deviceIdFilter !== null) {
      queryParameters = queryParameters.set('device-id-filter', <any>deviceIdFilter);
    }
    if (includeAllManagedDevices !== undefined && includeAllManagedDevices !== null) {
      queryParameters = queryParameters.set(
        'include-all-managed-devices',
        <any>includeAllManagedDevices
      );
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<GetBlockedUrlsResult>(`${this.basePath}/statistics/blocked-urls`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Get the blocking statistics
   * Loads the blocking page statistics.
   * @param includeAllManagedDevices include-all-managed-devices
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param days days
   * @param deviceIdFilter device-id-filter
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getBlockingStatisticsUsingGET(
    includeAllManagedDevices: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    days?: number,
    deviceIdFilter?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GetBlockingStatisticsResult>;
  public getBlockingStatisticsUsingGET(
    includeAllManagedDevices: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    days?: number,
    deviceIdFilter?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GetBlockingStatisticsResult>>;
  public getBlockingStatisticsUsingGET(
    includeAllManagedDevices: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    days?: number,
    deviceIdFilter?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GetBlockingStatisticsResult>>;
  public getBlockingStatisticsUsingGET(
    includeAllManagedDevices: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    days?: number,
    deviceIdFilter?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (includeAllManagedDevices === null || includeAllManagedDevices === undefined) {
      throw new Error(
        'Required parameter includeAllManagedDevices was null or undefined when calling getBlockingStatisticsUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (days !== undefined && days !== null) {
      queryParameters = queryParameters.set('days', <any>days);
    }
    if (deviceIdFilter !== undefined && deviceIdFilter !== null) {
      queryParameters = queryParameters.set('device-id-filter', <any>deviceIdFilter);
    }
    if (includeAllManagedDevices !== undefined && includeAllManagedDevices !== null) {
      queryParameters = queryParameters.set(
        'include-all-managed-devices',
        <any>includeAllManagedDevices
      );
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<GetBlockingStatisticsResult>(
      `${this.basePath}/statistics/blocking-statistics`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get device overview statistics
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeviceOverviewStatisticsUsingGET(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GetDeviceOverviewStatisticsResult>;
  public getDeviceOverviewStatisticsUsingGET(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GetDeviceOverviewStatisticsResult>>;
  public getDeviceOverviewStatisticsUsingGET(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GetDeviceOverviewStatisticsResult>>;
  public getDeviceOverviewStatisticsUsingGET(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<GetDeviceOverviewStatisticsResult>(
      `${this.basePath}/statistics/device-overview-statistics`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Load daily TLDs
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadDailyTLDsUsingGET(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LoadTLDsResult>;
  public loadDailyTLDsUsingGET(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LoadTLDsResult>>;
  public loadDailyTLDsUsingGET(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LoadTLDsResult>>;
  public loadDailyTLDsUsingGET(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<LoadTLDsResult>(`${this.basePath}/statistics/daily-tlds`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Load daily trends
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadDailyTrendsUsingGET(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LoadTrendsResult>;
  public loadDailyTrendsUsingGET(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LoadTrendsResult>>;
  public loadDailyTrendsUsingGET(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LoadTrendsResult>>;
  public loadDailyTrendsUsingGET(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<LoadTrendsResult>(`${this.basePath}/statistics/daily-trend`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Loads the security dashboard
   * Loads the security dashboard data (the data required by the app/web frontend to show the dashboard page).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadSecurityDashboardDataUsingGET(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LoadSecurityDashboardDataResult>;
  public loadSecurityDashboardDataUsingGET(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LoadSecurityDashboardDataResult>>;
  public loadSecurityDashboardDataUsingGET(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LoadSecurityDashboardDataResult>>;
  public loadSecurityDashboardDataUsingGET(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<LoadSecurityDashboardDataResult>(
      `${this.basePath}/statistics/security-dashboard-data`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Load threat level history
   *
   * @param currentDayOnly current-day-only
   * @param deviceIdFilter device-id-filter
   * @param includeAllManagedDevices include-all-managed-devices
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadThreatLevelHistoryUsingGET(
    currentDayOnly: boolean,
    deviceIdFilter: number,
    includeAllManagedDevices: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LoadThreatLevelHistoryResult>;
  public loadThreatLevelHistoryUsingGET(
    currentDayOnly: boolean,
    deviceIdFilter: number,
    includeAllManagedDevices: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LoadThreatLevelHistoryResult>>;
  public loadThreatLevelHistoryUsingGET(
    currentDayOnly: boolean,
    deviceIdFilter: number,
    includeAllManagedDevices: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LoadThreatLevelHistoryResult>>;
  public loadThreatLevelHistoryUsingGET(
    currentDayOnly: boolean,
    deviceIdFilter: number,
    includeAllManagedDevices: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (currentDayOnly === null || currentDayOnly === undefined) {
      throw new Error(
        'Required parameter currentDayOnly was null or undefined when calling loadThreatLevelHistoryUsingGET.'
      );
    }

    if (deviceIdFilter === null || deviceIdFilter === undefined) {
      throw new Error(
        'Required parameter deviceIdFilter was null or undefined when calling loadThreatLevelHistoryUsingGET.'
      );
    }

    if (includeAllManagedDevices === null || includeAllManagedDevices === undefined) {
      throw new Error(
        'Required parameter includeAllManagedDevices was null or undefined when calling loadThreatLevelHistoryUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (currentDayOnly !== undefined && currentDayOnly !== null) {
      queryParameters = queryParameters.set('current-day-only', <any>currentDayOnly);
    }
    if (deviceIdFilter !== undefined && deviceIdFilter !== null) {
      queryParameters = queryParameters.set('device-id-filter', <any>deviceIdFilter);
    }
    if (includeAllManagedDevices !== undefined && includeAllManagedDevices !== null) {
      queryParameters = queryParameters.set(
        'include-all-managed-devices',
        <any>includeAllManagedDevices
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<LoadThreatLevelHistoryResult>(
      `${this.basePath}/statistics/threat-level/history`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
