/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { CheckDomainResult } from '../model/checkDomainResult';
import { DomainCheckerDTO } from '../model/domainCheckerDTO';
import { PostDomainCheckerDTO } from '../model/postDomainCheckerDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class URLService {
  protected basePath = 'https://10.36.200.206:9021/cms-api-gateway/cloudsecurity/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check the domain against the user profiles (profile related to the authenticated device in the case of mobile-app access) and return a list of profiles blocking the domain.
   *
   * @param request request
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public applyDomainCheckerUsingPOST(
    request: PostDomainCheckerDTO,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DomainCheckerDTO>;
  public applyDomainCheckerUsingPOST(
    request: PostDomainCheckerDTO,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DomainCheckerDTO>>;
  public applyDomainCheckerUsingPOST(
    request: PostDomainCheckerDTO,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DomainCheckerDTO>>;
  public applyDomainCheckerUsingPOST(
    request: PostDomainCheckerDTO,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (request === null || request === undefined) {
      throw new Error(
        'Required parameter request was null or undefined when calling applyDomainCheckerUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DomainCheckerDTO>(`${this.basePath}/url/domain-checker`, request, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Check the phishing domain if no (device-id or mobile-app access). Check the domain (blocked or allowed)/category according to the profile of (device-id or mobile-app access).
   *
   * @param domain domain or url
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param deviceId device-id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkDomainUsingGET(
    domain: string,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    deviceId?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CheckDomainResult>;
  public checkDomainUsingGET(
    domain: string,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    deviceId?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CheckDomainResult>>;
  public checkDomainUsingGET(
    domain: string,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    deviceId?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CheckDomainResult>>;
  public checkDomainUsingGET(
    domain: string,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    deviceId?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (domain === null || domain === undefined) {
      throw new Error(
        'Required parameter domain was null or undefined when calling checkDomainUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (deviceId !== undefined && deviceId !== null) {
      queryParameters = queryParameters.set('device-id', <any>deviceId);
    }
    if (domain !== undefined && domain !== null) {
      queryParameters = queryParameters.set('domain', <any>domain);
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<CheckDomainResult>(`${this.basePath}/url/check-domain`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
