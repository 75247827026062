import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { SSOUtil } from '@app/modules/shared/utils';
import { LocalStorageKeys } from '@app/modules/shared/utils/utils';

const UNAUTHORIZED_STATUS_CODE = 401;

const DESCRIPTION_CODES_AUTH: string[] = [
  'RE_AUTHENTICATION_REQUIRED',
  'NOT_AUTHENTICATED',
  'INVALID_AUTH_HEADER'
];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor() {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(map(event => this.redirectIfNotAuthenticated(request, event)));
  }

  private redirectIfNotAuthenticated(
    request: HttpRequest<any>,
    event: HttpEvent<any>
  ): HttpEvent<any> {
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isApiUrl && event instanceof HttpResponse) {
      const result = event.body;
      if (
        (result?.resultCode !== 'OK' && DESCRIPTION_CODES_AUTH.includes(result?.descriptionCode)) ||
        event.status === UNAUTHORIZED_STATUS_CODE
      ) {
        localStorage.removeItem(LocalStorageKeys.TOKEN);
        SSOUtil.redirectToSSO();
      }
    }
    return event;
  }
}
