import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlockingStatistics } from '../models';
import {
  BlockedUrl,
  DomainExceptionService,
  DomainUnblockRequestDTO,
  MobileAppBlockingService,
  MobileAppUnblockRequestDTO,
  PutDomainUnblockRequestRequest,
  PutMobileAppUnblockRequestRequest,
  StatisticsControllerService,
  UnblockRequestsService
} from '../swagger-api';
import { AppBlocking } from '@app/modules/core/models/app-blocking';
import { DomainUnblockRequest } from '../models/unblock-requests/domain-unlock-request';
import { AppUnblockRequest } from '../models/unblock-requests/app-unlock-request';
import { ProfileService } from './profile.service';
import { DomainException } from '../models/domain-exceptions';

@Injectable()
export class BlockingService {
  constructor(
    private statisticsControllerService: StatisticsControllerService,
    private mobileAppBlockingService: MobileAppBlockingService,
    private unblockService: UnblockRequestsService,
    private profileService: ProfileService,
    private domainExceptionService: DomainExceptionService
  ) {}

  private getBlockingStatistics$(deviceId?: number): Observable<BlockingStatistics> {
    return this.statisticsControllerService
      .getBlockingStatisticsUsingGET(true, undefined, undefined, deviceId)
      .pipe(
        map(getBlockingStatisticsResult => BlockingStatistics.fromDTO(getBlockingStatisticsResult))
      );
  }

  public getAllBlockingStatistics$(): Observable<BlockingStatistics> {
    return this.getBlockingStatistics$();
  }

  private getBlockedUrls$(deviceId?: number): Observable<BlockedUrl[]> {
    return this.statisticsControllerService
      .getBlockedUrlsUsingGET(true, undefined, undefined, deviceId)
      .pipe(map(blockedUrlsResult => blockedUrlsResult.blockedUrls));
  }

  public getAllBlockedUrls$(): Observable<BlockedUrl[]> {
    return this.getBlockedUrls$();
  }

  public getAppBlockingsByProfileId$(profileId: number): Observable<AppBlocking[]> {
    return this.mobileAppBlockingService
      .getAppsUsingGET(profileId)
      .pipe(map(resultDTOs => resultDTOs.map(resultDTO => AppBlocking.fromDTO(resultDTO))));
  }

  public updateAppBlockings$(
    profileId: number,
    appBlockings: AppBlocking[]
  ): Observable<AppBlocking[]> {
    const appsBlockingDTOs = appBlockings.map(appBlocking => appBlocking.toDTO());
    return this.mobileAppBlockingService
      .updateAppsUsingPUT(appsBlockingDTOs, profileId)
      .pipe(map(resultDTOs => resultDTOs.map(resultDTO => AppBlocking.fromDTO(resultDTO))));
  }

  public resolveAppUnblockRequests$(
    mobileAppUnblockRequests: PutMobileAppUnblockRequestRequest[]
  ): Observable<MobileAppUnblockRequestDTO[]> {
    return this.unblockService.updateMobileAppUnblockRequestsUsingPUT(mobileAppUnblockRequests);
  }

  public resolveDomainUnblockRequests$(
    domainUnblockRequests: PutDomainUnblockRequestRequest[]
  ): Observable<DomainUnblockRequestDTO[]> {
    return this.unblockService.updateDomainUnblockRequestsUsingPUT(domainUnblockRequests);
  }

  public getDomainUnblockRequests$(): Observable<DomainUnblockRequest[]> {
    return forkJoin([
      this.unblockService.getDomainUnblockRequestsUsingGET(),
      this.profileService.getProfileDefinitions$()
    ]).pipe(
      map(([domainUnblockRequests, profiles]) => {
        return domainUnblockRequests.map(request => {
          const selectedProfileDefinition = profiles.find(
            profileDefinition => profileDefinition.id === request.profileId
          );
          return DomainUnblockRequest.fromDTO(request, selectedProfileDefinition);
        });
      })
    );
  }

  public getAppUnblockRequests$(): Observable<AppUnblockRequest[]> {
    return forkJoin([
      this.unblockService.getAppUnblockRequestsUsingGET(),
      this.profileService.getProfileDefinitions$()
    ]).pipe(
      map(([appUnblockRequests, profiles]) => {
        return appUnblockRequests.map(request => {
          const selectedProfileDefinition = profiles.find(
            profileDefinition => profileDefinition.id === request.profileId
          );
          return AppUnblockRequest.fromDTO(request, selectedProfileDefinition);
        });
      })
    );
  }

  public getDomainExceptions$(profileId: number): Observable<DomainException[]> {
    return this.domainExceptionService
      .getDomainExceptionsUsingGET(profileId)
      .pipe(map(dtos => dtos.map(dto => DomainException.fromDTO(dto))));
  }

  public createDomainException$(
    profileId: number,
    domainException: DomainException
  ): Observable<DomainException> {
    return this.createDomainExceptions$(profileId, [domainException]).pipe(
      map(result => result[0])
    );
  }

  public createDomainExceptions$(
    profileId: number,
    domainExceptions: DomainException[]
  ): Observable<DomainException[]> {
    const dtos = domainExceptions.map(exception => exception.toDTO());
    return this.domainExceptionService
      .createDomainExceptionsUsingPOST(dtos, profileId)
      .pipe(map(resultDTOs => resultDTOs.map(dto => DomainException.fromDTO(dto))));
  }

  public updateDomainException$(
    profileId: number,
    domainException: DomainException
  ): Observable<DomainException> {
    return this.updateDomainExceptions$(profileId, [domainException]).pipe(
      map(result => result[0])
    );
  }

  public updateDomainExceptions$(
    profileId: number,
    domainExceptions: DomainException[]
  ): Observable<DomainException[]> {
    const dtos = domainExceptions.map(domainException => domainException.toDTO());
    return this.domainExceptionService
      .updateDomainExceptionStatusesUsingPATCH(dtos, profileId)
      .pipe(map(resultDTOs => resultDTOs.map(resultDTO => DomainException.fromDTO(resultDTO))));
  }

  public deleteDomainExceptions$(
    profileId: number,
    domainExceptions: DomainException[]
  ): Observable<undefined> {
    const domainExceptionIds = domainExceptions.map(exception => exception.id);
    return this.domainExceptionService.deleteDomainExceptionsUsingDELETE(
      domainExceptionIds,
      profileId
    );
  }
}
