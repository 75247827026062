<mat-dialog-content class="dialog-content">
  <h4>{{ 'APP.UNSAVED_CHANGES' | translate }}</h4>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button color="primary" class="discard" [mat-dialog-close]="false">
    {{ 'APP.DISCARD' | translate }}
  </button>
  <button mat-flat-button color="primary" [mat-dialog-close]="true">
    {{ 'APP.SAVE' | translate }}
  </button>
</mat-dialog-actions>
