/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { CheckEmailIdentitiesInput } from '../model/checkEmailIdentitiesInput';
import { CheckEmailIdentitiesResult } from '../model/checkEmailIdentitiesResult';
import { CheckEmailIdentityResult } from '../model/checkEmailIdentityResult';
import { DeleteCheckEmailIdentityJobResult } from '../model/deleteCheckEmailIdentityJobResult';
import { DeleteEmailIdentityWarningExceptionResult } from '../model/deleteEmailIdentityWarningExceptionResult';
import { LoadCheckEmailIdentityJobsResult } from '../model/loadCheckEmailIdentityJobsResult';
import { LoadEmailIdentityWarningExceptionsResult } from '../model/loadEmailIdentityWarningExceptionsResult';
import { SaveCheckEmailIdentityJobResult } from '../model/saveCheckEmailIdentityJobResult';
import { SaveEmailIdentityCheckInputDTO } from '../model/saveEmailIdentityCheckInputDTO';
import { SaveEmailIdentityWarningExceptionInputDTO } from '../model/saveEmailIdentityWarningExceptionInputDTO';
import { SaveEmailIdentityWarningExceptionsResult } from '../model/saveEmailIdentityWarningExceptionsResult';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class EmailIdentityService {
  protected basePath = 'https://10.36.200.206:9021/cms-api-gateway/cloudsecurity/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Check and save multiple email identities
   *
   * @param emailIdentities emailIdentities
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkAndSaveEmailIdentitiesUsingPOST(
    emailIdentities: CheckEmailIdentitiesInput,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CheckEmailIdentitiesResult>;
  public checkAndSaveEmailIdentitiesUsingPOST(
    emailIdentities: CheckEmailIdentitiesInput,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CheckEmailIdentitiesResult>>;
  public checkAndSaveEmailIdentitiesUsingPOST(
    emailIdentities: CheckEmailIdentitiesInput,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CheckEmailIdentitiesResult>>;
  public checkAndSaveEmailIdentitiesUsingPOST(
    emailIdentities: CheckEmailIdentitiesInput,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (emailIdentities === null || emailIdentities === undefined) {
      throw new Error(
        'Required parameter emailIdentities was null or undefined when calling checkAndSaveEmailIdentitiesUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<CheckEmailIdentitiesResult>(
      `${this.basePath}/email-identity/check`,
      emailIdentities,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Check Email identity
   * Identity check: Checks if email address (account) data was leaked because of some hacked services.
   * @param email email
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkEmailIdentityUsingGET(
    email: string,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CheckEmailIdentityResult>;
  public checkEmailIdentityUsingGET(
    email: string,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CheckEmailIdentityResult>>;
  public checkEmailIdentityUsingGET(
    email: string,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CheckEmailIdentityResult>>;
  public checkEmailIdentityUsingGET(
    email: string,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (email === null || email === undefined) {
      throw new Error(
        'Required parameter email was null or undefined when calling checkEmailIdentityUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (email !== undefined && email !== null) {
      queryParameters = queryParameters.set('email', <any>email);
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<CheckEmailIdentityResult>(`${this.basePath}/email-identity/check`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Delete email identity jobs
   * Deletes an email addresses saved by the user for identity check.
   * @param jobId job-id
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteCheckEmailIdentityJobUsingDELETE(
    jobId: number,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DeleteCheckEmailIdentityJobResult>;
  public deleteCheckEmailIdentityJobUsingDELETE(
    jobId: number,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DeleteCheckEmailIdentityJobResult>>;
  public deleteCheckEmailIdentityJobUsingDELETE(
    jobId: number,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DeleteCheckEmailIdentityJobResult>>;
  public deleteCheckEmailIdentityJobUsingDELETE(
    jobId: number,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (jobId === null || jobId === undefined) {
      throw new Error(
        'Required parameter jobId was null or undefined when calling deleteCheckEmailIdentityJobUsingDELETE.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (jobId !== undefined && jobId !== null) {
      queryParameters = queryParameters.set('job-id', <any>jobId);
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<DeleteCheckEmailIdentityJobResult>(
      `${this.basePath}/email-identity/check-email-identity-job`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete email identity warning exception
   * Deletes an exception object assigned to the authenticated subscription stored for a leaked email address and a certain service.
   * @param warningExceptionIds warning-exception-ids
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteEmailIdentityWarningExceptionUsingDELETE(
    warningExceptionIds: Array<number>,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DeleteEmailIdentityWarningExceptionResult>;
  public deleteEmailIdentityWarningExceptionUsingDELETE(
    warningExceptionIds: Array<number>,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DeleteEmailIdentityWarningExceptionResult>>;
  public deleteEmailIdentityWarningExceptionUsingDELETE(
    warningExceptionIds: Array<number>,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DeleteEmailIdentityWarningExceptionResult>>;
  public deleteEmailIdentityWarningExceptionUsingDELETE(
    warningExceptionIds: Array<number>,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (warningExceptionIds === null || warningExceptionIds === undefined) {
      throw new Error(
        'Required parameter warningExceptionIds was null or undefined when calling deleteEmailIdentityWarningExceptionUsingDELETE.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (warningExceptionIds) {
      warningExceptionIds.forEach(element => {
        queryParameters = queryParameters.append('warning-exception-ids', <any>element);
      });
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<DeleteEmailIdentityWarningExceptionResult>(
      `${this.basePath}/email-identity/email-identity-warning-exceptions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Load email identity jobs
   * Loads configured email addresses saved by the user for identity check.
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadCheckEmailIdentityJobsUsingGET(
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LoadCheckEmailIdentityJobsResult>;
  public loadCheckEmailIdentityJobsUsingGET(
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LoadCheckEmailIdentityJobsResult>>;
  public loadCheckEmailIdentityJobsUsingGET(
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LoadCheckEmailIdentityJobsResult>>;
  public loadCheckEmailIdentityJobsUsingGET(
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<LoadCheckEmailIdentityJobsResult>(
      `${this.basePath}/email-identity/check-email-identity-job`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Load email identity warning exceptions
   * Loads the list of identity check exceptions assigned to the authenticated subscription.
   * @param email email
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadEmailIdentityWarningExceptionsUsingGET(
    email: string,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LoadEmailIdentityWarningExceptionsResult>;
  public loadEmailIdentityWarningExceptionsUsingGET(
    email: string,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LoadEmailIdentityWarningExceptionsResult>>;
  public loadEmailIdentityWarningExceptionsUsingGET(
    email: string,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LoadEmailIdentityWarningExceptionsResult>>;
  public loadEmailIdentityWarningExceptionsUsingGET(
    email: string,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (email === null || email === undefined) {
      throw new Error(
        'Required parameter email was null or undefined when calling loadEmailIdentityWarningExceptionsUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (email !== undefined && email !== null) {
      queryParameters = queryParameters.set('email', <any>email);
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<LoadEmailIdentityWarningExceptionsResult>(
      `${this.basePath}/email-identity/email-identity-warning-exceptions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Save subscription email address
   * Registers a new email address for the automatic identity check.
   * @param request request
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveCheckEmailIdentityJobUsingPOST(
    request: SaveEmailIdentityCheckInputDTO,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SaveCheckEmailIdentityJobResult>;
  public saveCheckEmailIdentityJobUsingPOST(
    request: SaveEmailIdentityCheckInputDTO,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SaveCheckEmailIdentityJobResult>>;
  public saveCheckEmailIdentityJobUsingPOST(
    request: SaveEmailIdentityCheckInputDTO,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SaveCheckEmailIdentityJobResult>>;
  public saveCheckEmailIdentityJobUsingPOST(
    request: SaveEmailIdentityCheckInputDTO,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (request === null || request === undefined) {
      throw new Error(
        'Required parameter request was null or undefined when calling saveCheckEmailIdentityJobUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<SaveCheckEmailIdentityJobResult>(
      `${this.basePath}/email-identity/check-email-identity-job`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Save Email identity warning exception
   * Saves an exception object for a leaked email address and a certain service. If a service was hacked before the time stored in the exception the leak will be ignored.
   * @param exceptions exceptions
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public saveEmailIdentityWarningExceptionUsingPOST(
    exceptions: Array<SaveEmailIdentityWarningExceptionInputDTO>,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SaveEmailIdentityWarningExceptionsResult>;
  public saveEmailIdentityWarningExceptionUsingPOST(
    exceptions: Array<SaveEmailIdentityWarningExceptionInputDTO>,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SaveEmailIdentityWarningExceptionsResult>>;
  public saveEmailIdentityWarningExceptionUsingPOST(
    exceptions: Array<SaveEmailIdentityWarningExceptionInputDTO>,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SaveEmailIdentityWarningExceptionsResult>>;
  public saveEmailIdentityWarningExceptionUsingPOST(
    exceptions: Array<SaveEmailIdentityWarningExceptionInputDTO>,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (exceptions === null || exceptions === undefined) {
      throw new Error(
        'Required parameter exceptions was null or undefined when calling saveEmailIdentityWarningExceptionUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<SaveEmailIdentityWarningExceptionsResult>(
      `${this.basePath}/email-identity/email-identity-warning-exceptions`,
      exceptions,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
