import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppMaterialModule } from '@app/modules/shared/app-material.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AccountService,
  BlockingService,
  CheckService,
  ConfigurationService,
  DeviceApiService,
  NotificationService,
  ProfileService,
  StatisticsService
} from './services';
import { ErrorInterceptor, JwtInterceptor, AuthInterceptor } from './interceptors';
import { HeaderComponent } from '@app/modules/shared/components/header/header.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/modules/shared/shared.module';
import { FooterComponent } from '@app/modules/shared/components/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { Store, StoreModule } from '@ngrx/store';
import * as fromCore from './core-ngrx/reducers/core.reducer';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { setAppConfig, setUser } from './core-ngrx/actions/core.actions';
import { SSOUtil } from '@app/modules/shared/utils';
import { LocalStorageKeys } from '../shared/utils/utils';
import { queryParamRemover } from '../shared/utils/query-param-remover';
import { AlertService } from '@app/modules/core/services/alert.service';

export function initApp(
  store$: Store,
  accountService: AccountService,
  configurationService: ConfigurationService
): () => Observable<any> {
  const tokenFromParams = new URLSearchParams(window.location.search).get('t') || '';
  const token = localStorage.getItem(LocalStorageKeys.TOKEN);

  if (tokenFromParams) {
    localStorage.setItem(LocalStorageKeys.TOKEN, tokenFromParams);
    window.location.href = queryParamRemover('t', tokenFromParams);
  } else if (!token) {
    SSOUtil.redirectToSSO();
  }

  return token
    ? () =>
        forkJoin([accountService.getUser$(), configurationService.getAppConfig$()]).pipe(
          tap(([user, appConfig]) => {
            store$.dispatch(setUser({ user }));
            store$.dispatch(setAppConfig({ appConfig }));
          }),
          // for some reason the HttpHandler in the error interceptor incorrectly emits the error response for the config call
          // therefore we must handle the 401 - Unauthorized error manually #FRONT-2387
          catchError(error => {
            if (error.status === 401) {
              SSOUtil.redirectToSSO();
            }
            return of(error);
          })
        )
    : // if there is no token, return an observable that never emits, so the initialization stops
      () => new Observable();
}

@NgModule({
  declarations: [HeaderComponent, FooterComponent],
  providers: [
    CheckService,
    NotificationService,
    ProfileService,
    DeviceApiService,
    BlockingService,
    StatisticsService,
    AlertService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [Store, AccountService, ConfigurationService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  imports: [
    AppMaterialModule,
    SharedModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    StoreModule.forFeature(fromCore.coreFeatureKey, fromCore.coreReducer)
  ],
  exports: [HeaderComponent, FooterComponent]
})
export class CoreModule {}
