import {
  DeviceDTO,
  OperatingSystemInfo,
  SaveDeviceDTO,
  UpdateDevicesRequestDTO
} from '@app/modules/core/swagger-api';
import { UntypedFormGroup } from '@angular/forms';
import { ProfileDefinition } from './profile-definition';

interface DeviceHardwareInfo {
  deviceModelName?: string;
}

type DeviceIcon =
  | 'smartphone'
  | 'language'
  | 'escalator_warning'
  | 'desktop_windows'
  | 'tap_and_play';

export type DeviceProtectionStatus = DeviceDTO.DeviceProtectionStatusEnum;
export const DeviceProtectionStatus = DeviceDTO.DeviceProtectionStatusEnum;
export type DeviceType = DeviceDTO.DeviceTypeEnum;
export const DeviceType = DeviceDTO.DeviceTypeEnum;

export const DeviceIconMap: Map<DeviceType, DeviceIcon> = new Map([
  [DeviceType.MOBILECHILDPROTECTION, 'escalator_warning'],
  [DeviceType.MOBILE, 'smartphone'],
  [DeviceType.ONNET, 'language'],
  [DeviceType.WINDOWS, 'desktop_windows'],
  [DeviceType.ONNETMOBILECHILDPROTECTION, 'tap_and_play']
]);

export class Device {
  public id: number;
  public protectionStatus: DeviceProtectionStatus;
  public macAddress: string;
  public msisdn: string;
  public name: string;
  public type: DeviceType;
  public operatingSystemInfo: OperatingSystemInfo;
  public profileDefinitionId: number;
  public profileDefinitionName: string;
  public hardware: DeviceHardwareInfo;

  constructor(
    id: number,
    protectionStatus: DeviceProtectionStatus,
    macAddress: string,
    msisdn: string,
    name: string,
    type: DeviceType,
    operatingSystemInfo: OperatingSystemInfo,
    profileDefinitionId: number,
    profileDefinitionName: string,
    hardware: DeviceHardwareInfo
  ) {
    this.id = id;
    this.protectionStatus = protectionStatus;
    this.macAddress = macAddress;
    this.msisdn = msisdn;
    this.name = name;
    this.type = type;
    this.operatingSystemInfo = operatingSystemInfo;
    this.profileDefinitionId = profileDefinitionId;
    this.profileDefinitionName = profileDefinitionName;
    this.hardware = hardware;
  }

  public toSaveDeviceDTO(pin?: string): SaveDeviceDTO {
    return {
      id: this.id,
      msisdn: this.msisdn,
      name: this.name,
      operatingSystemInfo: this.operatingSystemInfo,
      pin,
      profileDefinitionId: this.profileDefinitionId,
      type: this.type
    } as SaveDeviceDTO;
  }

  public static fromDTO(dto: DeviceDTO): Device {
    return new Device(
      dto.id,
      dto.deviceProtectionStatus,
      dto.macAddress,
      dto.msisdn,
      dto.name,
      dto.deviceType,
      dto.operatingSystemInfo,
      dto.profileDefinitionId,
      dto.profileDefinitionName,
      dto.hardware
    );
  }

  public static fromForm(form: UntypedFormGroup, deviceType: DeviceType): Device {
    const profileDefinition: ProfileDefinition = form.get('profileDefinition').value;
    return new Device(
      undefined,
      undefined,
      undefined,
      form.get('phoneNumber').value,
      form.get('name').value,
      deviceType,
      undefined,
      profileDefinition.id,
      profileDefinition.name,
      undefined
    );
  }

  public static toUpdateDevicesRequestDTO(devices: Device[]): UpdateDevicesRequestDTO {
    return {
      devices: devices.map(device => {
        return {
          id: device.id,
          name: device.name,
          profileDefinitionId: device.profileDefinitionId
        };
      })
    } as UpdateDevicesRequestDTO;
  }

  get isChildProtection(): boolean {
    return (
      this.type === DeviceType.MOBILECHILDPROTECTION ||
      this.type === DeviceType.ONNETMOBILECHILDPROTECTION
    );
  }
}
