export function extractDomain(url: string): string {
  url = url.replace('http://', '');
  url = url.replace('https://', '');
  url = url.replace('www.', '');
  url = url.split('/')[0];
  url = url.split(':')[0];
  url = url.toLocaleLowerCase();
  return url;
}

export const GLOBAL_MOBILE_VIEW_THRESHOLD = 768;

export enum ProductInformation {
  PRODUCT_CHILDPROTECTION = 1,
  PRODUCT_ONNET_ONLY = 2,
  PRODUCT_ONNET_WITH_ENDPOINT_SECURITY = 4,
  PRODUCT_FIXEDLINE_MULTIPLE_DEVICES = 5,
  PRODUCT_ENDPOINT_ONLY = 6
}

export enum LocalStorageKeys {
  TOKEN = 'token'
}
