/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DeleteDevicesResult } from '../model/deleteDevicesResult';
import { DeviceWithProfileRequest } from '../model/deviceWithProfileRequest';
import { DeviceWithProfileResponse } from '../model/deviceWithProfileResponse';
import { GetDevicesResult } from '../model/getDevicesResult';
import { MsisdnValidationRequest } from '../model/msisdnValidationRequest';
import { SaveDevicesRequestDTO } from '../model/saveDevicesRequestDTO';
import { SaveDevicesResult } from '../model/saveDevicesResult';
import { TuxguardDataDTO } from '../model/tuxguardDataDTO';
import { UpdateDevicesRequestDTO } from '../model/updateDevicesRequestDTO';
import { UpdateDevicesResult } from '../model/updateDevicesResult';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class DeviceService {
  protected basePath = 'https://10.36.200.206:9021/cms-api-gateway/cloudsecurity/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Create child device with new child profile
   * (MSISDN and PIN) are mandatory only for the ONNET_MOBILE_CHILD_PROTECTION type and they will be discarded for any other types.
   * @param request request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createDeviceWithProfileUsingPOST(
    request: DeviceWithProfileRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DeviceWithProfileResponse>;
  public createDeviceWithProfileUsingPOST(
    request: DeviceWithProfileRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DeviceWithProfileResponse>>;
  public createDeviceWithProfileUsingPOST(
    request: DeviceWithProfileRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DeviceWithProfileResponse>>;
  public createDeviceWithProfileUsingPOST(
    request: DeviceWithProfileRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (request === null || request === undefined) {
      throw new Error(
        'Required parameter request was null or undefined when calling createDeviceWithProfileUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<DeviceWithProfileResponse>(
      `${this.basePath}/devices/with-profile`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create devices
   * (MSISDN and PIN) are mandatory only for the ONNET_MOBILE_CHILD_PROTECTION type and they will be discarded for any other types.
   * @param request request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createDevicesUsingPOST(
    request: SaveDevicesRequestDTO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SaveDevicesResult>;
  public createDevicesUsingPOST(
    request: SaveDevicesRequestDTO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SaveDevicesResult>>;
  public createDevicesUsingPOST(
    request: SaveDevicesRequestDTO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SaveDevicesResult>>;
  public createDevicesUsingPOST(
    request: SaveDevicesRequestDTO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (request === null || request === undefined) {
      throw new Error(
        'Required parameter request was null or undefined when calling createDevicesUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<SaveDevicesResult>(`${this.basePath}/devices`, request, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Delete request
   *
   * @param deviceIds device-ids
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteDevicesUsingDELETE(
    deviceIds: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DeleteDevicesResult>;
  public deleteDevicesUsingDELETE(
    deviceIds: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DeleteDevicesResult>>;
  public deleteDevicesUsingDELETE(
    deviceIds: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DeleteDevicesResult>>;
  public deleteDevicesUsingDELETE(
    deviceIds: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (deviceIds === null || deviceIds === undefined) {
      throw new Error(
        'Required parameter deviceIds was null or undefined when calling deleteDevicesUsingDELETE.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (deviceIds) {
      deviceIds.forEach(element => {
        queryParameters = queryParameters.append('device-ids', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<DeleteDevicesResult>(`${this.basePath}/devices`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Get devices
   *
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDevicesUsingGET(
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GetDevicesResult>;
  public getDevicesUsingGET(
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GetDevicesResult>>;
  public getDevicesUsingGET(
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GetDevicesResult>>;
  public getDevicesUsingGET(
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<GetDevicesResult>(`${this.basePath}/devices`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Get tuxguard data for device
   *
   * @param deviceId deviceId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getTuxguardDataResultUsingGET(
    deviceId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<TuxguardDataDTO>;
  public getTuxguardDataResultUsingGET(
    deviceId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<TuxguardDataDTO>>;
  public getTuxguardDataResultUsingGET(
    deviceId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<TuxguardDataDTO>>;
  public getTuxguardDataResultUsingGET(
    deviceId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (deviceId === null || deviceId === undefined) {
      throw new Error(
        'Required parameter deviceId was null or undefined when calling getTuxguardDataResultUsingGET.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<TuxguardDataDTO>(
      `${this.basePath}/devices/${encodeURIComponent(String(deviceId))}/tuxguard-data`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update devices
   *
   * @param request request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDevicesUsingPUT(
    request: UpdateDevicesRequestDTO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UpdateDevicesResult>;
  public updateDevicesUsingPUT(
    request: UpdateDevicesRequestDTO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UpdateDevicesResult>>;
  public updateDevicesUsingPUT(
    request: UpdateDevicesRequestDTO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UpdateDevicesResult>>;
  public updateDevicesUsingPUT(
    request: UpdateDevicesRequestDTO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (request === null || request === undefined) {
      throw new Error(
        'Required parameter request was null or undefined when calling updateDevicesUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<UpdateDevicesResult>(`${this.basePath}/devices`, request, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Validate provided msisdn.
   * Attention! Endpoint does not create any resources. Its sole purpose is to check if msisdn is valid.
   * @param request request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public validateMsisdnUsingPOST(
    request: MsisdnValidationRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public validateMsisdnUsingPOST(
    request: MsisdnValidationRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public validateMsisdnUsingPOST(
    request: MsisdnValidationRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public validateMsisdnUsingPOST(
    request: MsisdnValidationRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (request === null || request === undefined) {
      throw new Error(
        'Required parameter request was null or undefined when calling validateMsisdnUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/devices/msisdn-validation`, request, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
