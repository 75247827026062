import { Injectable } from '@angular/core';
import { BlockedUrl } from '@app/modules/core/swagger-api';
import { TranslateService } from '@ngx-translate/core';
import { CustomDatePipe } from '@cms/ngx-cyan-utils';

interface ProcessedUrl {
  url: string;
  date: string;
  profileName: string;
}

export const blockedURlDateComparator = (entryA: BlockedUrl, entryB: BlockedUrl): number =>
  entryA.timestampUtc < entryB.timestampUtc ? 1 : -1;

@Injectable()
export class BlockedUrlsProcessor {
  constructor(private customDate: CustomDatePipe, private translate: TranslateService) {}

  public processBlockedUrls(blockedUrls: BlockedUrl[]): ProcessedUrl[] {
    const processedUrls: ProcessedUrl[] = [];
    blockedUrls.forEach((blockedUrl: BlockedUrl) => {
      processedUrls.push({
        url: `${blockedUrl.url} (${blockedUrl.blockingCount}x)`,
        date:
          this.translate.instant('DASHBOARD.DASHBOARD.BLOCKING_HISTORY.LAST_ACCESS') +
          ' ' +
          this.customDate.transform(blockedUrl.timestampUtc),
        profileName: blockedUrl.profileName
      });
    });

    return processedUrls;
  }
}
