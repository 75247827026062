import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from '@app/modules/shared/app-material.module';
import { SharedModule } from '@app/modules/shared/shared.module';
import { CoreModule } from '@app/modules/core/core.module';
import { AccountService } from '@app/modules/core/services';
import { environment } from '@environments/environment';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { LocalStorageKeys } from './modules/shared/utils/utils';
import {
  ApiModule as ApiModuleV0,
  Configuration,
  ConfigurationParameters
} from '@app/modules/core/swagger-api';
import { ApiModule as ApiModuleV1 } from '@app/modules/core/swagger-api-v1';
import { getTranslateModuleConfig } from '@cms/ngx-cyan-utils';
import { providerConfig } from 'src/providerconfig';

export function apiConfigurationFactory(): Configuration {
  const token = localStorage.getItem(LocalStorageKeys.TOKEN);

  const configurationParameters: ConfigurationParameters = {
    apiKeys: { Authorization: token },
    basePath: environment.basePath
  };

  return new Configuration(configurationParameters);
}

const translationModuleConfig = getTranslateModuleConfig(
  providerConfig.languages,
  providerConfig.defaultLanguage
);

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    CoreModule,
    ApiModuleV0.forRoot(apiConfigurationFactory),
    ApiModuleV1.forRoot(apiConfigurationFactory),
    BrowserModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'My-Xsrf-Cookie',
      headerName: 'My-Xsrf-Header'
    }),
    AppRoutingModule,
    AppMaterialModule,
    TranslateModule.forRoot(translationModuleConfig),
    BrowserAnimationsModule,
    FormsModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : []
  ],
  providers: [AccountService, Title],
  bootstrap: [AppComponent]
})
export class AppModule {}
