import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CheckEmailIdentitiesResult } from '../swagger-api/model/checkEmailIdentitiesResult';
import { CheckEmailIdentitiesInput } from '../swagger-api/model/checkEmailIdentitiesInput';
import { EmailIdentityService, URLService } from '../swagger-api/api/api';
import {
  CheckEmailIdentityService,
  DeleteCheckEmailIdentityJobResult,
  DomainCheckerDTO,
  SaveEmailIdentityCheckInputDTO,
  SaveEmailIdentityWarningExceptionsResult
} from '../swagger-api';
import { EmailIdentityJob } from '../models';

@Injectable()
export class CheckService {
  constructor(
    private emailIdentityService: EmailIdentityService,
    private urlControllerService: URLService
  ) {}

  public checkEmailIdentity$(email: string): Observable<CheckEmailIdentityService[]> {
    return this.emailIdentityService
      .checkEmailIdentityUsingGET(email)
      .pipe(map(result => result.breachedServices));
  }

  public checkEmailIdentities$(emails: string[]): Observable<CheckEmailIdentitiesResult> {
    const emailIdentities: CheckEmailIdentitiesInput = {
      emailAddresses: emails
    };
    return this.emailIdentityService.checkAndSaveEmailIdentitiesUsingPOST(emailIdentities);
  }

  public saveEmailIdentityWarningExceptions$(
    exceptions: any[]
  ): Observable<SaveEmailIdentityWarningExceptionsResult> {
    return this.emailIdentityService.saveEmailIdentityWarningExceptionUsingPOST(exceptions);
  }

  public getAllEmails$(): Observable<EmailIdentityJob[]> {
    return this.emailIdentityService
      .loadCheckEmailIdentityJobsUsingGET()
      .pipe(
        map(loadCheckEmailIdentityJobsResult =>
          loadCheckEmailIdentityJobsResult.emailIdentityJobs.map(emailIdentityJobDTO =>
            EmailIdentityJob.fromDTO(emailIdentityJobDTO)
          )
        )
      );
  }

  public registerEmail$(email: string): Observable<EmailIdentityJob> {
    const saveEmailIdentityCheckInputDTO: SaveEmailIdentityCheckInputDTO = {
      emailAddress: email
    };
    return this.emailIdentityService
      .saveCheckEmailIdentityJobUsingPOST(saveEmailIdentityCheckInputDTO)
      .pipe(map(result => EmailIdentityJob.fromDTO(result.subscriptionEmailAddress)));
  }

  public removeEmail$(email: EmailIdentityJob): Observable<DeleteCheckEmailIdentityJobResult> {
    return this.emailIdentityService.deleteCheckEmailIdentityJobUsingDELETE(email.id);
  }

  public checkWebsite$(url: string): Observable<DomainCheckerDTO> {
    return this.urlControllerService.applyDomainCheckerUsingPOST({ url }).pipe(
      map(result => result),
      catchError(() => of(null))
    );
  }
}
