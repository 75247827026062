/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PutDomainUnblockRequestRequest {
  blockingStatus: PutDomainUnblockRequestRequest.BlockingStatusEnum;
  id: number;
}
export namespace PutDomainUnblockRequestRequest {
  export type BlockingStatusEnum = 'ALLOWED' | 'BLOCKED';
  export const BlockingStatusEnum = {
    ALLOWED: 'ALLOWED' as BlockingStatusEnum,
    BLOCKED: 'BLOCKED' as BlockingStatusEnum
  };
}
