import { SSOUtil } from '@app/modules/shared/utils';

const UNAUTHORIZED_RESPONSE_CODE = 401;

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
export class AuthInterceptor implements HttpInterceptor {
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === UNAUTHORIZED_RESPONSE_CODE) {
          SSOUtil.redirectToSSO();
        }
        return throwError(response);
      })
    );
  }
}
