import { EmailIdentityJobDTO } from '../swagger-api';

export class EmailIdentityJob {
  public id: number;
  public deviceId: number;
  public ownerUserId: number;
  public emailAddress: string;

  constructor(id: number, deviceId: number, ownerUserId: number, emailAddress: string) {
    this.id = id;
    this.deviceId = deviceId;
    this.ownerUserId = ownerUserId;
    this.emailAddress = emailAddress;
  }

  public static fromDTO(dto: EmailIdentityJobDTO): EmailIdentityJob {
    return new EmailIdentityJob(dto.id, dto.deviceId, dto.ownerUserId, dto.emailAddress);
  }
}
