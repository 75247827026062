export const queryParamRemover = (paramKey: string, paramValue: string): string => {
  const url = window.location.href;
  const domain = url.split('?')[0];
  const params = url.split('?')[1].split('&');
  // remove queryParam
  params.splice(params.indexOf(`${paramKey}=${paramValue}`), 1);

  // rejoin params with domain manually to make sure no extra reserved characters are in the URL
  return domain + '?' + params.join('&');
};
