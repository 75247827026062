import { DomainUnblockRequestDTO } from '../../swagger-api/model/domainUnblockRequestDTO';
import { PutDomainUnblockRequestRequest } from '../../swagger-api/model/putDomainUnblockRequestRequest';
import { ProfileDefinition } from '../profile-definition';
import { BlockingStatusEnum } from './blocking-status-enum';

export class DomainUnblockRequest {
  public blockingReason: number;
  public categoryId: number;
  public domain: string;
  public id: number;
  public requestedAt: string;
  public profileId: number;
  public profileName: string;
  public categoryTranslationKey: string;
  public blockingReasonTranslationKey: string;

  constructor(
    blockingReason: number,
    categoryId: number,
    domain: string,
    id: number,
    requestedAt: string,
    profileId: number,
    profileName: string,
    categoryTranslationKey: string,
    blockingReasonTranslationKey: string
  ) {
    this.blockingReason = blockingReason;
    this.categoryId = categoryId;
    this.domain = domain;
    this.id = id;
    this.profileId = profileId;
    this.requestedAt = requestedAt;
    this.profileName = profileName;
    this.categoryTranslationKey = categoryTranslationKey;
    this.blockingReasonTranslationKey = blockingReasonTranslationKey;
  }

  public static fromDTO(
    dto: DomainUnblockRequestDTO,
    profile: ProfileDefinition
  ): DomainUnblockRequest {
    return new DomainUnblockRequest(
      dto.blockingReason,
      dto.categoryId,
      dto.domain,
      dto.id,
      dto.requestedAt,
      profile.id,
      profile.name,
      this.getCategoryLabel(dto.categoryId),
      this.getBlockingReasonLabel(dto.blockingReason)
    );
  }

  public toDTO(blockingStatus: BlockingStatusEnum): PutDomainUnblockRequestRequest {
    return {
      blockingStatus,
      id: this.id
    } as PutDomainUnblockRequestRequest;
  }

  public static getCategoryLabel(categoryId: number): string {
    return 'CATEGORIES.' + categoryId + '.LABEL';
  }

  public static getBlockingReasonLabel(blockingReason: number): string {
    switch (blockingReason) {
      case 3:
        return 'DASHBOARD.BLOCKING_HISTORY_DETAIL.BLOCKED_DURING_WORKING_TIME';
      default:
        return 'DASHBOARD.BLOCKING_HISTORY_DETAIL.BLOCKED_PERMANENTLY';
    }
  }
}
