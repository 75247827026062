import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationControllerService } from '../swagger-api';
import { SelfCareConfig } from '../models/selfcare-config';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(private appControllerService: ApplicationControllerService) {}

  public getAppConfig$(): Observable<SelfCareConfig> {
    return this.appControllerService.getConfigurationUsingGET();
  }
}
