/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { GetDeviceProfilesResult } from '../model/getDeviceProfilesResult';
import { UpdateDeviceProfileRequestDTO } from '../model/updateDeviceProfileRequestDTO';
import { UpdateDeviceProfileResult } from '../model/updateDeviceProfileResult';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class DeviceProfileService {
  protected basePath = 'https://10.36.200.206:9021/cms-api-gateway/cloudsecurity/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Get Device Profiles
   * Deprecated as it is not used by Selfcare-ui.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDeviceProfilesUsingGET(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GetDeviceProfilesResult>;
  public getDeviceProfilesUsingGET(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GetDeviceProfilesResult>>;
  public getDeviceProfilesUsingGET(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GetDeviceProfilesResult>>;
  public getDeviceProfilesUsingGET(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<GetDeviceProfilesResult>(`${this.basePath}/device-profile`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Update a device profile (override the settings defined in the assigned profile definition).
   * Deprecated as it is not used by Selfcare-ui.
   * @param request request
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDeviceProfileUsingPOST(
    request: UpdateDeviceProfileRequestDTO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UpdateDeviceProfileResult>;
  public updateDeviceProfileUsingPOST(
    request: UpdateDeviceProfileRequestDTO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UpdateDeviceProfileResult>>;
  public updateDeviceProfileUsingPOST(
    request: UpdateDeviceProfileRequestDTO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UpdateDeviceProfileResult>>;
  public updateDeviceProfileUsingPOST(
    request: UpdateDeviceProfileRequestDTO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (request === null || request === undefined) {
      throw new Error(
        'Required parameter request was null or undefined when calling updateDeviceProfileUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<UpdateDeviceProfileResult>(
      `${this.basePath}/device-profile`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
