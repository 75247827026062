import { Device, ProfileDefinition, User } from '@app/modules/core/models';
import { createAction, props } from '@ngrx/store';
import { DeviceLicenses } from '../../models/device-license';
import { SelfCareConfig } from '../../models/selfcare-config';

// the "Core" in the actioncreator could be a certain page name too,
// depends on where we want to fire the action from
export const setProfileDefinitions = createAction(
  '[Core] set all ProfileDefinitions',
  props<{ profileDefinitions: ProfileDefinition[] }>()
);

export const setDevices = createAction('[Core] set Devices', props<{ devices: Device[] }>());

export const setUser = createAction('[Core] set User', props<{ user: User }>());

export const setAppInitData = createAction(
  '[Core] set app initialization data',
  props<{
    devices: Device[];
    profileDefinitions: ProfileDefinition[];
  }>()
);

export const setAppConfig = createAction(
  '[Core] set app configuration',
  props<{
    appConfig: SelfCareConfig;
  }>()
);

export const setDeviceLicenses = createAction(
  '[Core] Set device licenses',
  props<{ deviceLicenses: DeviceLicenses }>()
);
