import { MobileAppBlockingDTO } from '@app/modules/core/swagger-api';

export const BlockingStatusEnum = MobileAppBlockingDTO.BlockingStatusEnum;
export type BlockingStatusEnum = MobileAppBlockingDTO.BlockingStatusEnum;

export class AppBlocking {
  public packageName: string;
  public blockingStatus: BlockingStatusEnum;
  public name: string;

  constructor(androidId: string, blockingStatus: BlockingStatusEnum, name: string) {
    this.packageName = androidId;
    this.blockingStatus = blockingStatus;
    this.name = name;
  }

  public static fromDTO(dto: MobileAppBlockingDTO): AppBlocking {
    return new AppBlocking(dto.packageName, dto.blockingStatus, dto.name);
  }

  public toDTO(): MobileAppBlockingDTO {
    return {
      packageName: this.packageName,
      blockingStatus: this.blockingStatus,
      name: this.name
    } as MobileAppBlockingDTO;
  }
}
