import { Injectable } from '@angular/core';
import {
  DeleteProfileDefinitionResult,
  ProfileDefinitionService as ProfileDefinitionServiceV0
} from '@app/modules/core/swagger-api';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ProfileDefinition } from '@app/modules/core/models';
import { map, tap } from 'rxjs/operators';
import { setProfileDefinitions } from '@app/modules/core/core-ngrx/actions/core.actions';
import { CloneProfileDTOV1, ProfileService as ProfileDefinitionServiceV1 } from '../swagger-api-v1';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private profileDefinitionServiceV0: ProfileDefinitionServiceV0,
    private profileDefinitionServiceV1: ProfileDefinitionServiceV1,
    private store$: Store
  ) {}

  public getProfileDefinitions$(): Observable<ProfileDefinition[]> {
    return this.profileDefinitionServiceV0
      .getProfileDefinitionsUsingGET(true)
      .pipe(
        map(profileDefinitionResult =>
          profileDefinitionResult.profileDefinitions.map(profileDefinition =>
            ProfileDefinition.fromDTO(profileDefinition)
          )
        )
      );
  }

  public createProfileDefinition$(
    profileName?: string,
    profileType?: 'KID' | 'TEEN'
  ): Observable<ProfileDefinition> {
    return this.profileDefinitionServiceV0
      .createUsingPOST(profileName, profileType)
      .pipe(
        map(updateProfileDefinitionResult =>
          ProfileDefinition.fromDTO(updateProfileDefinitionResult.profileDefinition)
        )
      );
  }

  public copyProfileDefinition$(id: number, prefix: string): Observable<number> {
    const queryDTO: CloneProfileDTOV1 = {
      namePrefix: prefix
    };

    return this.profileDefinitionServiceV1
      .cloneProfileUsingPOST(id, queryDTO)
      .pipe(map(result => result.id));
  }

  public updateProfileDefinition$(
    profileDefinition: ProfileDefinition
  ): Observable<ProfileDefinition> {
    return this.profileDefinitionServiceV0
      .updateProfileDefinitionUsingPUT(profileDefinition.toInputDTO())
      .pipe(
        map(saveProfileDefinitionResult =>
          ProfileDefinition.fromDTO(saveProfileDefinitionResult.profileDefinition)
        )
      );
  }

  public deleteProfileDefinition$(id: number): Observable<DeleteProfileDefinitionResult> {
    return this.profileDefinitionServiceV0.deleteProfileDefinitionUsingDELETE(true, id);
  }

  public updateStoredProfiles$(): Observable<ProfileDefinition[]> {
    return this.getProfileDefinitions$().pipe(
      tap(profileDefinitions => {
        this.store$.dispatch(setProfileDefinitions({ profileDefinitions }));
      })
    );
  }
}
