/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface NotificationCenterItem {
  level?: NotificationCenterItem.LevelEnum;
  params?: any;
  type?: NotificationCenterItem.TypeEnum;
}
export namespace NotificationCenterItem {
  export type LevelEnum = 'INFO' | 'WARN';
  export const LevelEnum = {
    INFO: 'INFO' as LevelEnum,
    WARN: 'WARN' as LevelEnum
  };
  export type TypeEnum =
    | 'LICENSE__EXPIRED'
    | 'DEVICE__NOT_INITIALIZED'
    | 'DEVICE__ACCESSIBILITY_PERMISSION_MISSING'
    | 'DEVICE__USAGE_STATISTIC_PERMISSION_MISSING'
    | 'DEVICE__MEDIA_AND_FILES_PERMISSION_MISSING'
    | 'DEVICE__SAFE_CONNECTION_MISSING'
    | 'DEVICE__INFECTED_APPLICATIONS_FOUND'
    | 'DEVICE__INFECTED_FILES_FOUND'
    | 'DEVICE__UNLOCK_REQUEST'
    | 'DEVICE__APP_UNBLOCK_REQUEST'
    | 'PROFILE__INTERNET_OFF'
    | 'EMAIL__BREACH'
    | 'DOMAIN__TOO_MANY_OVERRIDE';
  export const TypeEnum = {
    LICENSEEXPIRED: 'LICENSE__EXPIRED' as TypeEnum,
    DEVICENOTINITIALIZED: 'DEVICE__NOT_INITIALIZED' as TypeEnum,
    DEVICEACCESSIBILITYPERMISSIONMISSING: 'DEVICE__ACCESSIBILITY_PERMISSION_MISSING' as TypeEnum,
    DEVICEUSAGESTATISTICPERMISSIONMISSING: 'DEVICE__USAGE_STATISTIC_PERMISSION_MISSING' as TypeEnum,
    DEVICEMEDIAANDFILESPERMISSIONMISSING: 'DEVICE__MEDIA_AND_FILES_PERMISSION_MISSING' as TypeEnum,
    DEVICESAFECONNECTIONMISSING: 'DEVICE__SAFE_CONNECTION_MISSING' as TypeEnum,
    DEVICEINFECTEDAPPLICATIONSFOUND: 'DEVICE__INFECTED_APPLICATIONS_FOUND' as TypeEnum,
    DEVICEINFECTEDFILESFOUND: 'DEVICE__INFECTED_FILES_FOUND' as TypeEnum,
    DEVICEUNLOCKREQUEST: 'DEVICE__UNLOCK_REQUEST' as TypeEnum,
    DEVICEAPPUNBLOCKREQUEST: 'DEVICE__APP_UNBLOCK_REQUEST' as TypeEnum,
    PROFILEINTERNETOFF: 'PROFILE__INTERNET_OFF' as TypeEnum,
    EMAILBREACH: 'EMAIL__BREACH' as TypeEnum,
    DOMAINTOOMANYOVERRIDE: 'DOMAIN__TOO_MANY_OVERRIDE' as TypeEnum
  };
}
