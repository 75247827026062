import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import {
  AdministrativeService,
  DeviceService,
  DeviceWithProfileRequest,
  DeviceWithProfileResponse,
  LicenseService
} from '../swagger-api';
import { Device, DeviceType } from '../models';
import { Store } from '@ngrx/store';
import coreActions from '../core-ngrx/actions';
import { DeviceLicenses } from '../models/device-license';
import { AlertService } from './alert.service';

@Injectable()
export class DeviceApiService {
  constructor(
    private licenseService: LicenseService,
    private deviceService: DeviceService,
    private store$: Store,
    private administrativeService: AdministrativeService,
    private alertService: AlertService
  ) {}

  public getAll$(): Observable<Device[]> {
    return this.deviceService
      .getDevicesUsingGET()
      .pipe(
        map(getDevicesResult =>
          getDevicesResult.devices.map(deviceDTO => Device.fromDTO(deviceDTO))
        )
      );
  }

  public save$(device: Device, pin?: string): Observable<Device> {
    const saveDeviceDTO = device.toSaveDeviceDTO(pin);

    return this.deviceService
      .createDevicesUsingPOST({ devices: [saveDeviceDTO] })
      .pipe(
        map(
          saveDevicesResult =>
            saveDevicesResult.devices.map(deviceDTO => Device.fromDTO(deviceDTO))[0]
        )
      );
  }

  public validatePhoneNumber$(msisdn: string): Observable<boolean> {
    return this.deviceService.validateMsisdnUsingPOST({ msisdn }).pipe(
      map(() => true),
      catchError(response => {
        this.alertService.error(`DEVICE.OVERVIEW.ERROR.${response?.error?.descriptionCode}`);
        return of(false);
      })
    );
  }

  public updateDevices$(devices: Device[]): Observable<Device[]> {
    return this.deviceService
      .updateDevicesUsingPUT(Device.toUpdateDevicesRequestDTO(devices))
      .pipe(map(result => result.devices.map(deviceDTO => Device.fromDTO(deviceDTO))));
  }

  public deleteDevice$(deviceIds: Array<number>): Observable<unknown> {
    return this.deviceService
      .deleteDevicesUsingDELETE(deviceIds)
      .pipe(switchMap(() => forkJoin([this.updateStoredDevices$(), this.updateStoredLicenses$()])));
  }

  public updateStoredDevices$(): Observable<Device[]> {
    return this.getAll$().pipe(
      tap(devices => {
        this.store$.dispatch(coreActions.setDevices({ devices }));
      })
    );
  }

  public updateStoredLicenses$(): Observable<DeviceLicenses> {
    return this.licenseService.getDevicesLicenseUsingGET().pipe(
      map(result => DeviceLicenses.fromResult(result)),
      filter(deviceLicenses => !!deviceLicenses),
      tap(deviceLicenses => {
        this.store$.dispatch(coreActions.setDeviceLicenses({ deviceLicenses }));
      })
    );
  }

  public createDeviceWithProfile$(
    deviceName: string,
    deviceType: DeviceType,
    profileName: string,
    profileType: DeviceWithProfileRequest.ProfileTypeEnum,
    phoneNumber?: string,
    pin?: string
  ): Observable<DeviceWithProfileResponse> {
    const request: DeviceWithProfileRequest = {
      deviceName,
      // NB: Creating device with profile is only available for child devices
      deviceType: deviceType as DeviceWithProfileRequest.DeviceTypeEnum,
      profileName,
      profileType,
      msisdn: phoneNumber,
      pin
    };
    return this.deviceService.createDeviceWithProfileUsingPOST(request);
  }

  public getWindowsAppDownloadUrl$(deviceId: number): Observable<string> {
    return this.deviceService
      .getTuxguardDataResultUsingGET(deviceId)
      .pipe(map(result => result.windowsAppInstallerDownloadUrl));
  }

  public requestPinForPhoneNumber$(phoneNumber: string): Observable<boolean> {
    return this.administrativeService.generateAndSendPinUsingPOST({ msisdn: phoneNumber }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
