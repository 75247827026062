/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DeregisterChildSubscriptionResult } from '../model/deregisterChildSubscriptionResult';
import { GetManagedSubscribersResult } from '../model/getManagedSubscribersResult';
import { RegisterDeviceResult } from '../model/registerDeviceResult';
import { SearchCustomerResult } from '../model/searchCustomerResult';
import { UpdateSubscriberDetailsResult } from '../model/updateSubscriberDetailsResult';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class SubscriptionService {
  protected basePath = 'https://10.36.200.206:9021/cms-api-gateway/cloudsecurity/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Deregister child subscription
   * Deprecated as it is not used by Selfcare-ui.
   * @param childSubscriptionId child-subscription-id
   * @param subscriptionId subscription-id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deregisterChildSubscriptionUsingDELETE(
    childSubscriptionId: number,
    subscriptionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DeregisterChildSubscriptionResult>;
  public deregisterChildSubscriptionUsingDELETE(
    childSubscriptionId: number,
    subscriptionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DeregisterChildSubscriptionResult>>;
  public deregisterChildSubscriptionUsingDELETE(
    childSubscriptionId: number,
    subscriptionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DeregisterChildSubscriptionResult>>;
  public deregisterChildSubscriptionUsingDELETE(
    childSubscriptionId: number,
    subscriptionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (childSubscriptionId === null || childSubscriptionId === undefined) {
      throw new Error(
        'Required parameter childSubscriptionId was null or undefined when calling deregisterChildSubscriptionUsingDELETE.'
      );
    }

    if (subscriptionId === null || subscriptionId === undefined) {
      throw new Error(
        'Required parameter subscriptionId was null or undefined when calling deregisterChildSubscriptionUsingDELETE.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (childSubscriptionId !== undefined && childSubscriptionId !== null) {
      queryParameters = queryParameters.set('child-subscription-id', <any>childSubscriptionId);
    }
    if (subscriptionId !== undefined && subscriptionId !== null) {
      queryParameters = queryParameters.set('subscription-id', <any>subscriptionId);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<DeregisterChildSubscriptionResult>(
      `${this.basePath}/subscription/child-subscription`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get managed subscribers
   * Deprecated as it is not used by Selfcare-ui.
   * @param listOffset list-offset
   * @param rowLimit row-limit
   * @param subscriptionId subscription-id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getManagedSubscribersUsingGET(
    listOffset: number,
    rowLimit: number,
    subscriptionId?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GetManagedSubscribersResult>;
  public getManagedSubscribersUsingGET(
    listOffset: number,
    rowLimit: number,
    subscriptionId?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GetManagedSubscribersResult>>;
  public getManagedSubscribersUsingGET(
    listOffset: number,
    rowLimit: number,
    subscriptionId?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GetManagedSubscribersResult>>;
  public getManagedSubscribersUsingGET(
    listOffset: number,
    rowLimit: number,
    subscriptionId?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (listOffset === null || listOffset === undefined) {
      throw new Error(
        'Required parameter listOffset was null or undefined when calling getManagedSubscribersUsingGET.'
      );
    }

    if (rowLimit === null || rowLimit === undefined) {
      throw new Error(
        'Required parameter rowLimit was null or undefined when calling getManagedSubscribersUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (listOffset !== undefined && listOffset !== null) {
      queryParameters = queryParameters.set('list-offset', <any>listOffset);
    }
    if (rowLimit !== undefined && rowLimit !== null) {
      queryParameters = queryParameters.set('row-limit', <any>rowLimit);
    }
    if (subscriptionId !== undefined && subscriptionId !== null) {
      queryParameters = queryParameters.set('subscription-id', <any>subscriptionId);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<GetManagedSubscribersResult>(
      `${this.basePath}/subscription/managed-subscribers`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Register child subscription
   * Deprecated as it is not used by Selfcare-ui.
   * @param childFirstName
   * @param childFormOfAddress
   * @param childLastName
   * @param deviceId
   * @param msisdn
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public registerChildSubscriptionUsingPOST(
    childFirstName?: string,
    childFormOfAddress?: number,
    childLastName?: string,
    deviceId?: number,
    msisdn?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RegisterDeviceResult>;
  public registerChildSubscriptionUsingPOST(
    childFirstName?: string,
    childFormOfAddress?: number,
    childLastName?: string,
    deviceId?: number,
    msisdn?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RegisterDeviceResult>>;
  public registerChildSubscriptionUsingPOST(
    childFirstName?: string,
    childFormOfAddress?: number,
    childLastName?: string,
    deviceId?: number,
    msisdn?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RegisterDeviceResult>>;
  public registerChildSubscriptionUsingPOST(
    childFirstName?: string,
    childFormOfAddress?: number,
    childLastName?: string,
    deviceId?: number,
    msisdn?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (childFirstName !== undefined && childFirstName !== null) {
      queryParameters = queryParameters.set('childFirstName', <any>childFirstName);
    }
    if (childFormOfAddress !== undefined && childFormOfAddress !== null) {
      queryParameters = queryParameters.set('childFormOfAddress', <any>childFormOfAddress);
    }
    if (childLastName !== undefined && childLastName !== null) {
      queryParameters = queryParameters.set('childLastName', <any>childLastName);
    }
    if (deviceId !== undefined && deviceId !== null) {
      queryParameters = queryParameters.set('deviceId', <any>deviceId);
    }
    if (msisdn !== undefined && msisdn !== null) {
      queryParameters = queryParameters.set('msisdn', <any>msisdn);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<RegisterDeviceResult>(
      `${this.basePath}/subscription/child-subscription`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Search customer
   * Deprecated as it is not used by Selfcare-ui.
   * @param subscriptionId subscription-id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public searchCustomerUsingGET(
    subscriptionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<SearchCustomerResult>;
  public searchCustomerUsingGET(
    subscriptionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<SearchCustomerResult>>;
  public searchCustomerUsingGET(
    subscriptionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<SearchCustomerResult>>;
  public searchCustomerUsingGET(
    subscriptionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (subscriptionId === null || subscriptionId === undefined) {
      throw new Error(
        'Required parameter subscriptionId was null or undefined when calling searchCustomerUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (subscriptionId !== undefined && subscriptionId !== null) {
      queryParameters = queryParameters.set('subscription-id', <any>subscriptionId);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<SearchCustomerResult>(`${this.basePath}/subscription/customer`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * update subscriber details
   * Deprecated as it is not used by Selfcare-ui.
   * @param firstName
   * @param formOfAddress
   * @param lastName
   * @param subscriptionDbIdChild
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateSubscriberDetailsUsingPOST(
    firstName?: string,
    formOfAddress?: 'NONE' | 'HERR' | 'FRAU',
    lastName?: string,
    subscriptionDbIdChild?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UpdateSubscriberDetailsResult>;
  public updateSubscriberDetailsUsingPOST(
    firstName?: string,
    formOfAddress?: 'NONE' | 'HERR' | 'FRAU',
    lastName?: string,
    subscriptionDbIdChild?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UpdateSubscriberDetailsResult>>;
  public updateSubscriberDetailsUsingPOST(
    firstName?: string,
    formOfAddress?: 'NONE' | 'HERR' | 'FRAU',
    lastName?: string,
    subscriptionDbIdChild?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UpdateSubscriberDetailsResult>>;
  public updateSubscriberDetailsUsingPOST(
    firstName?: string,
    formOfAddress?: 'NONE' | 'HERR' | 'FRAU',
    lastName?: string,
    subscriptionDbIdChild?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (firstName !== undefined && firstName !== null) {
      queryParameters = queryParameters.set('firstName', <any>firstName);
    }
    if (formOfAddress !== undefined && formOfAddress !== null) {
      queryParameters = queryParameters.set('formOfAddress', <any>formOfAddress);
    }
    if (lastName !== undefined && lastName !== null) {
      queryParameters = queryParameters.set('lastName', <any>lastName);
    }
    if (subscriptionDbIdChild !== undefined && subscriptionDbIdChild !== null) {
      queryParameters = queryParameters.set('subscriptionDbIdChild', <any>subscriptionDbIdChild);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<UpdateSubscriberDetailsResult>(
      `${this.basePath}/subscription/subscriber-details`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
