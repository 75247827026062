import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { DeviceApiService, ProfileService } from '@app/modules/core/services';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { combineLatest } from 'rxjs';
import { finalize, first, tap } from 'rxjs/operators';
import { setAppInitData } from './modules/core/core-ngrx/actions/core.actions';
import { Store } from '@ngrx/store';
import { IconService } from '@app/modules/core/services/icon.service';
import { providerConfig } from 'src/providerconfig';

@Component({
  selector: 'selfcare-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public loading = false;

  constructor(
    public translate: TranslateService,
    private router: Router,
    private titleService: Title,
    private profileService: ProfileService,
    private deviceService: DeviceApiService,
    private store$: Store,
    private iconService: IconService
  ) {
    this.iconService.loadIcons();

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  public ngOnInit(): void {
    combineLatest([
      this.deviceService.getAll$(),
      this.profileService.getProfileDefinitions$(),
      this.deviceService.updateStoredLicenses$()
    ])
      .pipe(
        first(),
        tap(([devices, profileDefinitions]) => {
          this.store$.dispatch(setAppInitData({ devices, profileDefinitions }));
          this.titleService.setTitle(providerConfig.productName);
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe();
  }
}
