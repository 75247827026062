import { MobileAppUnblockRequestDTO } from '../../swagger-api/model/mobileAppUnblockRequestDTO';
import { PutMobileAppUnblockRequestRequest } from '../../swagger-api/model/putMobileAppUnblockRequestRequest';
import { BlockingStatusEnum } from '../app-blocking';
import { ProfileDefinition } from '../profile-definition';

export class AppUnblockRequest {
  public name: string;
  public packageName: string;
  public requestedAt: string;
  public profileId: number;
  public profileName: string;

  constructor(
    name: string,
    packageName: string,
    requestedAt: string,
    profileId: number,
    profileName: string
  ) {
    this.name = name;
    this.packageName = packageName;
    this.requestedAt = requestedAt;
    this.profileId = profileId;
    this.profileName = profileName;
  }

  public static fromDTO(
    dto: MobileAppUnblockRequestDTO,
    profile: ProfileDefinition
  ): AppUnblockRequest {
    return new AppUnblockRequest(
      dto.name,
      dto.packageName,
      dto.requestedAt,
      dto.profileId,
      profile.name
    );
  }

  public toDTO(blockingStatus: BlockingStatusEnum): PutMobileAppUnblockRequestRequest {
    return {
      blockingStatus,
      packageName: this.packageName,
      profileId: this.profileId
    } as PutMobileAppUnblockRequestRequest;
  }
}
