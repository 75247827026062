import { CoreState } from '../reducers/core.reducer';

export const initialCoreSate: CoreState = {
  profileDefinitions: [],
  devices: [],
  user: null,
  appConfig: null,
  deviceLicenses: {
    mobile: {
      available: null,
      capacity: null
    },
    mobileChildProtection: {
      available: null,
      capacity: null
    },
    onnet: {
      available: null,
      capacity: null
    },
    onnetMobile: {
      available: null,
      capacity: null
    },
    onnetMobileChildProtection: {
      available: null,
      capacity: null
    },
    totalCapacity: null,
    totalUsed: null,
    windows: {
      available: null,
      capacity: null
    }
  }
};
