/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DomainExceptionDTO } from '../model/domainExceptionDTO';
import { PatchDomainExceptionRequest } from '../model/patchDomainExceptionRequest';
import { PostDomainExceptionDTO } from '../model/postDomainExceptionDTO';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class DomainExceptionService {
  protected basePath = 'https://10.36.200.206:9021/cms-api-gateway/cloudsecurity/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Create profile&#39;s domain exceptions
   *
   * @param domainExceptions domainExceptions
   * @param profileId profileId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createDomainExceptionsUsingPOST(
    domainExceptions: Array<PostDomainExceptionDTO>,
    profileId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<DomainExceptionDTO>>;
  public createDomainExceptionsUsingPOST(
    domainExceptions: Array<PostDomainExceptionDTO>,
    profileId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<DomainExceptionDTO>>>;
  public createDomainExceptionsUsingPOST(
    domainExceptions: Array<PostDomainExceptionDTO>,
    profileId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DomainExceptionDTO>>>;
  public createDomainExceptionsUsingPOST(
    domainExceptions: Array<PostDomainExceptionDTO>,
    profileId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (domainExceptions === null || domainExceptions === undefined) {
      throw new Error(
        'Required parameter domainExceptions was null or undefined when calling createDomainExceptionsUsingPOST.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling createDomainExceptionsUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<Array<DomainExceptionDTO>>(
      `${this.basePath}/profiles/${encodeURIComponent(String(profileId))}/domain-exceptions`,
      domainExceptions,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete domain exception related to profile with provided id and having provided ids
   *
   * @param ids ids
   * @param profileId profileId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteDomainExceptionsUsingDELETE(
    ids: Array<number>,
    profileId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteDomainExceptionsUsingDELETE(
    ids: Array<number>,
    profileId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteDomainExceptionsUsingDELETE(
    ids: Array<number>,
    profileId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteDomainExceptionsUsingDELETE(
    ids: Array<number>,
    profileId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (ids === null || ids === undefined) {
      throw new Error(
        'Required parameter ids was null or undefined when calling deleteDomainExceptionsUsingDELETE.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling deleteDomainExceptionsUsingDELETE.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (ids) {
      ids.forEach(element => {
        queryParameters = queryParameters.append('ids', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.basePath}/profiles/${encodeURIComponent(String(profileId))}/domain-exceptions`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return profile&#39;s domain exceptions
   *
   * @param profileId profileId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDomainExceptionsUsingGET(
    profileId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<DomainExceptionDTO>>;
  public getDomainExceptionsUsingGET(
    profileId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<DomainExceptionDTO>>>;
  public getDomainExceptionsUsingGET(
    profileId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DomainExceptionDTO>>>;
  public getDomainExceptionsUsingGET(
    profileId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling getDomainExceptionsUsingGET.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<DomainExceptionDTO>>(
      `${this.basePath}/profiles/${encodeURIComponent(String(profileId))}/domain-exceptions`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Update statuses of domain exceptions related to profile with provided id
   *
   * @param domainExceptions domainExceptions
   * @param profileId profileId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDomainExceptionStatusesUsingPATCH(
    domainExceptions: Array<PatchDomainExceptionRequest>,
    profileId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<DomainExceptionDTO>>;
  public updateDomainExceptionStatusesUsingPATCH(
    domainExceptions: Array<PatchDomainExceptionRequest>,
    profileId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<DomainExceptionDTO>>>;
  public updateDomainExceptionStatusesUsingPATCH(
    domainExceptions: Array<PatchDomainExceptionRequest>,
    profileId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DomainExceptionDTO>>>;
  public updateDomainExceptionStatusesUsingPATCH(
    domainExceptions: Array<PatchDomainExceptionRequest>,
    profileId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (domainExceptions === null || domainExceptions === undefined) {
      throw new Error(
        'Required parameter domainExceptions was null or undefined when calling updateDomainExceptionStatusesUsingPATCH.'
      );
    }

    if (profileId === null || profileId === undefined) {
      throw new Error(
        'Required parameter profileId was null or undefined when calling updateDomainExceptionStatusesUsingPATCH.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.patch<Array<DomainExceptionDTO>>(
      `${this.basePath}/profiles/${encodeURIComponent(String(profileId))}/domain-exceptions`,
      domainExceptions,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
