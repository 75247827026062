import { Injectable } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { UnsavedChangesComponent } from '@app/modules/shared/components';

@Injectable()
export class UnsavedChangesService {
  public unsavedChanges = false;
  private leavingPageSubject$: Subject<void>;

  constructor(private dialog: MatDialog) {}

  public leavingPage$() {
    this.leavingPageSubject$ = new Subject<void>();
    return this.leavingPageSubject$;
  }

  public canDeactivate(): Observable<boolean> {
    if (!this.unsavedChanges) {
      return of(true);
    }

    const dialogRef = this.dialog.open(UnsavedChangesComponent);

    return dialogRef.afterClosed().pipe(
      map(result => {
        if (result) {
          this.leavingPageSubject$.next();
          this.leavingPageSubject$.complete();
        }
        this.unsavedChanges = false;

        return true;
      })
    );
  }
}
