/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface MobileAppBlockingDTO {
  blockingStatus: MobileAppBlockingDTO.BlockingStatusEnum;
  name: string;
  packageName: string;
}
export namespace MobileAppBlockingDTO {
  export type BlockingStatusEnum = 'ALLOWED' | 'BLOCKED' | 'BLOCKED_BY_SCHEDULE';
  export const BlockingStatusEnum = {
    ALLOWED: 'ALLOWED' as BlockingStatusEnum,
    BLOCKED: 'BLOCKED' as BlockingStatusEnum,
    BLOCKEDBYSCHEDULE: 'BLOCKED_BY_SCHEDULE' as BlockingStatusEnum
  };
}
