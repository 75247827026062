import { Timetable, TimetableItem } from '@app/modules/core/swagger-api';

export class WorkingTime {
  public timeFrom: string;
  public timeTo: string;
  public weekdays: Map<string, boolean>;

  constructor(timeFrom: string, timeTo: string, weekdays: Map<string, boolean>) {
    this.timeFrom = timeFrom;
    this.timeTo = timeTo;
    this.weekdays = weekdays;
  }

  public static fromTimetable(
    timetable: Timetable,
    timeFrom = '07:00',
    timeTo = '17:00'
  ): WorkingTime {
    const workingTime = new WorkingTime(
      timeFrom,
      timeTo,
      new Map([
        ['mon', false],
        ['tue', false],
        ['wed', false],
        ['thu', false],
        ['fri', false],
        ['sat', false],
        ['sun', false]
      ])
    );
    timetable?.timetableItems.forEach(timeTableItem => {
      workingTime.weekdays.set(timeTableItem.weekday, true);
      workingTime.timeFrom = timeTableItem.start;
      workingTime.timeTo = timeTableItem.end;
    });

    return workingTime;
  }

  public toTimetable(): Timetable {
    const timetableItems: TimetableItem[] = [];

    this.weekdays?.forEach((value, key) => {
      if (value) {
        const timetableItem: TimetableItem = {
          start: this.timeFrom,
          end: this.timeTo,
          weekday: key
        };
        timetableItems.push(timetableItem);
      }
    });

    return { timetableItems } as Timetable;
  }
}
