import { createReducer, on } from '@ngrx/store';
import coreActions from '../actions';
import { Device, ProfileDefinition, User } from '@app/modules/core/models';
import { DeviceLicenses } from '../../models/device-license';
import { initialCoreSate } from '../initialState/core.initial-state';
import { SelfCareConfig } from '../../models/selfcare-config';

export const coreFeatureKey = 'core';

export interface CoreState {
  profileDefinitions: ProfileDefinition[];
  devices: Device[];
  user: User;
  appConfig: SelfCareConfig;
  deviceLicenses: DeviceLicenses;
}

export const coreReducer = createReducer(
  initialCoreSate,
  on(coreActions.setProfileDefinitions, (state, action): CoreState => {
    return {
      ...state,
      profileDefinitions: action.profileDefinitions
    };
  }),
  on(coreActions.setDevices, (state, action): CoreState => {
    return {
      ...state,
      devices: action.devices
    };
  }),
  on(coreActions.setUser, (state, action): CoreState => {
    return {
      ...state,
      user: action.user
    };
  }),
  on(coreActions.setAppInitData, (state, action): CoreState => {
    return {
      ...state,
      devices: action.devices,
      profileDefinitions: action.profileDefinitions
    };
  }),
  on(coreActions.setAppConfig, (state, action): CoreState => {
    return {
      ...state,
      appConfig: action.appConfig
    };
  }),
  on(coreActions.setDeviceLicenses, (state, action): CoreState => {
    return {
      ...state,
      deviceLicenses: action.deviceLicenses
    };
  })
);
