import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from './app-material.module';
import { PageLayoutComponent, UnsavedChangesComponent } from './components';
import { DialogComponent } from './components/dialog/dialog.component';
import { LicenseKeyPipe, NoDuplicatesPipe } from './pipes';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { GraphComponent } from './components/graph/graph.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { RadioSelectCardComponent } from './components/radio-select-card/radio-select-card.component';
import { TableComponent } from './components/table/table.component';
import { SelectionListComponent } from './components/select-list/selection-list.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { ExpandingMenuComponent } from './components/expanding-menu/expanding-menu.component';
import { MenuOptionComponent } from './components/expanding-menu/menu-option/menu-option.component';
import { BlockedUrlsProcessor } from './utils/blocked-url-utils';
import { TileStyleRadioSelectComponent } from './components/tile-style-radio-select/tile-style-radio-select.component';
import { ButtonWithSpinnerComponent } from './components/button-with-spinner/button-with-spinner.component';
import { UnsavedChangesService } from '@app/modules/shared/components/unsaved-changes/unsaved-changes.service';
import { CardWithSelectComponent } from './components/card-with-select/card-with-select.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { IconWithBadgeComponent } from './components/protection-status-badge/icon-with-badge.component';
import { NgxCyanUtilsModule } from '@cms/ngx-cyan-utils';

@NgModule({
  declarations: [
    UnsavedChangesComponent,
    DialogComponent,
    LicenseKeyPipe,
    PageLayoutComponent,
    NoDuplicatesPipe,
    GraphComponent,
    FileInputComponent,
    RadioSelectCardComponent,
    TableComponent,
    SelectionListComponent,
    ConfirmationDialogComponent,
    ExpandingMenuComponent,
    MenuOptionComponent,
    TileStyleRadioSelectComponent,
    ButtonWithSpinnerComponent,
    CardWithSelectComponent,
    SkeletonComponent,
    IconWithBadgeComponent
  ],
  providers: [UnsavedChangesService, BlockedUrlsProcessor],
  imports: [AppMaterialModule, CommonModule, TranslateModule, FormsModule, NgxCyanUtilsModule],
  exports: [
    AppMaterialModule,
    NgxCyanUtilsModule,
    UnsavedChangesComponent,
    LicenseKeyPipe,
    PageLayoutComponent,
    NoDuplicatesPipe,
    GraphComponent,
    FileInputComponent,
    RadioSelectCardComponent,
    TableComponent,
    SelectionListComponent,
    ConfirmationDialogComponent,
    ExpandingMenuComponent,
    MenuOptionComponent,
    TileStyleRadioSelectComponent,
    ButtonWithSpinnerComponent,
    CardWithSelectComponent,
    SkeletonComponent,
    IconWithBadgeComponent
  ]
})
export class SharedModule {}
