/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DomainUnblockRequestDTO } from '../model/domainUnblockRequestDTO';
import { MobileAppUnblockRequestDTO } from '../model/mobileAppUnblockRequestDTO';
import { PutDomainUnblockRequestRequest } from '../model/putDomainUnblockRequestRequest';
import { PutMobileAppUnblockRequestRequest } from '../model/putMobileAppUnblockRequestRequest';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class UnblockRequestsService {
  protected basePath = 'https://10.36.200.206:9021/cms-api-gateway/cloudsecurity/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Return pending app unblock requests in all profiles managed by represented user and a group
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppUnblockRequestsUsingGET(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<MobileAppUnblockRequestDTO>>;
  public getAppUnblockRequestsUsingGET(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<MobileAppUnblockRequestDTO>>>;
  public getAppUnblockRequestsUsingGET(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<MobileAppUnblockRequestDTO>>>;
  public getAppUnblockRequestsUsingGET(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<MobileAppUnblockRequestDTO>>(
      `${this.basePath}/app-unblock-requests`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Return pending domain unblock requests in all profiles managed by represented user and a group
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDomainUnblockRequestsUsingGET(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<DomainUnblockRequestDTO>>;
  public getDomainUnblockRequestsUsingGET(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<DomainUnblockRequestDTO>>>;
  public getDomainUnblockRequestsUsingGET(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DomainUnblockRequestDTO>>>;
  public getDomainUnblockRequestsUsingGET(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<DomainUnblockRequestDTO>>(
      `${this.basePath}/domain-unblock-requests`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Resolve domain unblock requests provided in the request body
   * Only provided domain unblock requests will be resolved, returns all pending domain unblock requests information
   * @param domainUnblockRequests domainUnblockRequests
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateDomainUnblockRequestsUsingPUT(
    domainUnblockRequests: Array<PutDomainUnblockRequestRequest>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<DomainUnblockRequestDTO>>;
  public updateDomainUnblockRequestsUsingPUT(
    domainUnblockRequests: Array<PutDomainUnblockRequestRequest>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<DomainUnblockRequestDTO>>>;
  public updateDomainUnblockRequestsUsingPUT(
    domainUnblockRequests: Array<PutDomainUnblockRequestRequest>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<DomainUnblockRequestDTO>>>;
  public updateDomainUnblockRequestsUsingPUT(
    domainUnblockRequests: Array<PutDomainUnblockRequestRequest>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (domainUnblockRequests === null || domainUnblockRequests === undefined) {
      throw new Error(
        'Required parameter domainUnblockRequests was null or undefined when calling updateDomainUnblockRequestsUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<Array<DomainUnblockRequestDTO>>(
      `${this.basePath}/domain-unblock-requests`,
      domainUnblockRequests,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Resolve mobile app unblock requests provided in the request body
   * Only provided mobile app unblock requests will be resolved, returns all pending mobile app unblock requests information
   * @param mobileAppUnblockRequests mobileAppUnblockRequests
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateMobileAppUnblockRequestsUsingPUT(
    mobileAppUnblockRequests: Array<PutMobileAppUnblockRequestRequest>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<MobileAppUnblockRequestDTO>>;
  public updateMobileAppUnblockRequestsUsingPUT(
    mobileAppUnblockRequests: Array<PutMobileAppUnblockRequestRequest>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<MobileAppUnblockRequestDTO>>>;
  public updateMobileAppUnblockRequestsUsingPUT(
    mobileAppUnblockRequests: Array<PutMobileAppUnblockRequestRequest>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<MobileAppUnblockRequestDTO>>>;
  public updateMobileAppUnblockRequestsUsingPUT(
    mobileAppUnblockRequests: Array<PutMobileAppUnblockRequestRequest>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (mobileAppUnblockRequests === null || mobileAppUnblockRequests === undefined) {
      throw new Error(
        'Required parameter mobileAppUnblockRequests was null or undefined when calling updateMobileAppUnblockRequestsUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<Array<MobileAppUnblockRequestDTO>>(
      `${this.basePath}/app-unblock-requests`,
      mobileAppUnblockRequests,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
