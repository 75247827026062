import { ProviderProductDTO } from '../swagger-api';

export class ProviderProduct {
  public id: number;
  public cmsProductCode: number;
  public internalProductId: string;
  public internalProductGroupId: string;
  public externalProductId: string;
  public externalChildProductId: string;
  public providerId: number;
  public enabled: boolean;
  public assignable: boolean;
  public businessModel: number;

  constructor(
    id: number,
    cmsProductCode: number,
    internalProductId: string,
    internalProductGroupId: string,
    externalProductId: string,
    externalChildProductId: string,
    providerId: number,
    enabled: boolean,
    assignable: boolean,
    businessModel: number
  ) {
    this.id = id;
    this.cmsProductCode = cmsProductCode;
    this.internalProductId = internalProductId;
    this.internalProductGroupId = internalProductGroupId;
    this.externalProductId = externalProductId;
    this.externalChildProductId = externalChildProductId;
    this.providerId = providerId;
    this.enabled = enabled;
    this.assignable = assignable;
    this.businessModel = businessModel;
  }

  public static fromDTO(dto: ProviderProductDTO): ProviderProduct {
    return new ProviderProduct(
      dto.id,
      dto.cmsProductCode,
      dto.internalProductId,
      dto.internalProductGroupId,
      dto.externalProductId,
      dto.externalChildProductId,
      dto.providerId,
      dto.enabled,
      dto.assignable,
      dto.providerProductParameters.businessModel
    );
  }
}
