import { DevicesLicenseResponse, License } from '@app/modules/core/swagger-api';

export class DeviceLicenses {
  public mobile: License;
  public mobileChildProtection: License;
  public onnet: License;
  public onnetMobile: License;
  public onnetMobileChildProtection: License;
  public totalCapacity: number;
  public totalUsed: number;
  public windows: License;

  constructor(
    mobile: License,
    mobileChildProtection: License,
    onnet: License,
    onnetMobile: License,
    onnetMobileChildProtection: License,
    totalCapacity: number,
    totalUsed: number,
    windows: License
  ) {
    this.mobile = mobile;
    this.mobileChildProtection = mobileChildProtection;
    this.onnet = onnet;
    this.onnetMobile = onnetMobile;
    this.onnetMobileChildProtection = onnetMobileChildProtection;
    this.totalCapacity = totalCapacity;
    this.totalUsed = totalUsed;
    this.windows = windows;
  }

  public static fromResult(result: DevicesLicenseResponse): DeviceLicenses {
    return new DeviceLicenses(
      result.mobile,
      result.mobileChildProtection,
      result.onnet,
      result.onnetMobile,
      result.onnetMobileChildProtection,
      result.totalCapacity,
      result.totalUsed,
      result.windows
    );
  }
}
