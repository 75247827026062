/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { DeleteProfileDefinitionResult } from '../model/deleteProfileDefinitionResult';
import { GetProfileDefinitionsResult } from '../model/getProfileDefinitionsResult';
import { ProfileDefinitionBasicInfoDTO } from '../model/profileDefinitionBasicInfoDTO';
import { ProfileDefinitionDTO } from '../model/profileDefinitionDTO';
import { ProfileDefinitionInputDTO } from '../model/profileDefinitionInputDTO';
import { ProfileDefinitionResponse } from '../model/profileDefinitionResponse';
import { UpdateProfileDefinitionResult } from '../model/updateProfileDefinitionResult';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class ProfileDefinitionService {
  protected basePath = 'https://10.36.200.206:9021/cms-api-gateway/cloudsecurity/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Create or copy profile definition
   *
   * @param source Optional. If provided the source profile definition is copied
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public copyUsingPOST(
    source?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UpdateProfileDefinitionResult>;
  public copyUsingPOST(
    source?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UpdateProfileDefinitionResult>>;
  public copyUsingPOST(
    source?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UpdateProfileDefinitionResult>>;
  public copyUsingPOST(
    source?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (source !== undefined && source !== null) {
      queryParameters = queryParameters.set('source', <any>source);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<UpdateProfileDefinitionResult>(
      `${this.basePath}/profile-definition/copy`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create profile definition
   *
   * @param name profile definition name
   * @param type profile definition type
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createUsingPOST(
    name?: string,
    type?: 'KID' | 'TEEN',
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProfileDefinitionResponse>;
  public createUsingPOST(
    name?: string,
    type?: 'KID' | 'TEEN',
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProfileDefinitionResponse>>;
  public createUsingPOST(
    name?: string,
    type?: 'KID' | 'TEEN',
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProfileDefinitionResponse>>;
  public createUsingPOST(
    name?: string,
    type?: 'KID' | 'TEEN',
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (name !== undefined && name !== null) {
      queryParameters = queryParameters.set('name', <any>name);
    }
    if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set('type', <any>type);
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];

    return this.httpClient.post<ProfileDefinitionResponse>(
      `${this.basePath}/profile-definition`,
      null,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Delete profile definition
   *
   * @param autoResetAffectedDevices auto-reset-affected-devices
   * @param profileDefinitionId profile-definition-id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteProfileDefinitionUsingDELETE(
    autoResetAffectedDevices: boolean,
    profileDefinitionId: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<DeleteProfileDefinitionResult>;
  public deleteProfileDefinitionUsingDELETE(
    autoResetAffectedDevices: boolean,
    profileDefinitionId: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<DeleteProfileDefinitionResult>>;
  public deleteProfileDefinitionUsingDELETE(
    autoResetAffectedDevices: boolean,
    profileDefinitionId: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<DeleteProfileDefinitionResult>>;
  public deleteProfileDefinitionUsingDELETE(
    autoResetAffectedDevices: boolean,
    profileDefinitionId: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (autoResetAffectedDevices === null || autoResetAffectedDevices === undefined) {
      throw new Error(
        'Required parameter autoResetAffectedDevices was null or undefined when calling deleteProfileDefinitionUsingDELETE.'
      );
    }

    if (profileDefinitionId === null || profileDefinitionId === undefined) {
      throw new Error(
        'Required parameter profileDefinitionId was null or undefined when calling deleteProfileDefinitionUsingDELETE.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (autoResetAffectedDevices !== undefined && autoResetAffectedDevices !== null) {
      queryParameters = queryParameters.set(
        'auto-reset-affected-devices',
        <any>autoResetAffectedDevices
      );
    }

    let headers = this.defaultHeaders;

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<DeleteProfileDefinitionResult>(
      `${this.basePath}/profile-definition/${encodeURIComponent(String(profileDefinitionId))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get profile definition extended data by id
   * Deprecated as it is not used by Selfcare-ui.
   * @param profileDefinitionId profile-definition-id
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProfileDefinitionExtendedDataUsingGET(
    profileDefinitionId: number,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ProfileDefinitionDTO>;
  public getProfileDefinitionExtendedDataUsingGET(
    profileDefinitionId: number,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<ProfileDefinitionDTO>>;
  public getProfileDefinitionExtendedDataUsingGET(
    profileDefinitionId: number,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<ProfileDefinitionDTO>>;
  public getProfileDefinitionExtendedDataUsingGET(
    profileDefinitionId: number,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (profileDefinitionId === null || profileDefinitionId === undefined) {
      throw new Error(
        'Required parameter profileDefinitionId was null or undefined when calling getProfileDefinitionExtendedDataUsingGET.'
      );
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<ProfileDefinitionDTO>(
      `${this.basePath}/profile-definition/${encodeURIComponent(String(profileDefinitionId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get profile definitions - basic information
   * Deprecated as it is not used by Selfcare-ui.
   * @param includeAllManagedProfileDefinitions include-all-managed-profile-definitions
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param profileDefinitionIds profile-definition-ids
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProfileDefinitionsBasicInfoUsingGET(
    includeAllManagedProfileDefinitions: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    profileDefinitionIds?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<ProfileDefinitionBasicInfoDTO>>;
  public getProfileDefinitionsBasicInfoUsingGET(
    includeAllManagedProfileDefinitions: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    profileDefinitionIds?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<ProfileDefinitionBasicInfoDTO>>>;
  public getProfileDefinitionsBasicInfoUsingGET(
    includeAllManagedProfileDefinitions: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    profileDefinitionIds?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<ProfileDefinitionBasicInfoDTO>>>;
  public getProfileDefinitionsBasicInfoUsingGET(
    includeAllManagedProfileDefinitions: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    profileDefinitionIds?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (
      includeAllManagedProfileDefinitions === null ||
      includeAllManagedProfileDefinitions === undefined
    ) {
      throw new Error(
        'Required parameter includeAllManagedProfileDefinitions was null or undefined when calling getProfileDefinitionsBasicInfoUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (
      includeAllManagedProfileDefinitions !== undefined &&
      includeAllManagedProfileDefinitions !== null
    ) {
      queryParameters = queryParameters.set(
        'include-all-managed-profile-definitions',
        <any>includeAllManagedProfileDefinitions
      );
    }
    if (profileDefinitionIds) {
      profileDefinitionIds.forEach(element => {
        queryParameters = queryParameters.append('profile-definition-ids', <any>element);
      });
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<ProfileDefinitionBasicInfoDTO>>(
      `${this.basePath}/profile-definition/basic-info`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Get profile definitions
   *
   * @param includeAllManagedProfileDefinitions include-all-managed-profile-definitions
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param profileDefinitionIds profile-definition-ids
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getProfileDefinitionsUsingGET(
    includeAllManagedProfileDefinitions: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    profileDefinitionIds?: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<GetProfileDefinitionsResult>;
  public getProfileDefinitionsUsingGET(
    includeAllManagedProfileDefinitions: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    profileDefinitionIds?: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<GetProfileDefinitionsResult>>;
  public getProfileDefinitionsUsingGET(
    includeAllManagedProfileDefinitions: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    profileDefinitionIds?: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<GetProfileDefinitionsResult>>;
  public getProfileDefinitionsUsingGET(
    includeAllManagedProfileDefinitions: boolean,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    profileDefinitionIds?: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (
      includeAllManagedProfileDefinitions === null ||
      includeAllManagedProfileDefinitions === undefined
    ) {
      throw new Error(
        'Required parameter includeAllManagedProfileDefinitions was null or undefined when calling getProfileDefinitionsUsingGET.'
      );
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (
      includeAllManagedProfileDefinitions !== undefined &&
      includeAllManagedProfileDefinitions !== null
    ) {
      queryParameters = queryParameters.set(
        'include-all-managed-profile-definitions',
        <any>includeAllManagedProfileDefinitions
      );
    }
    if (profileDefinitionIds) {
      profileDefinitionIds.forEach(element => {
        queryParameters = queryParameters.append('profile-definition-ids', <any>element);
      });
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<GetProfileDefinitionsResult>(`${this.basePath}/profile-definition`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Update profile definition
   *
   * @param request request
   * @param X_CYAN_AUTHENTICATION_METHOD The authentication method the client would like to use. Accepted values: PROVIDER_DEFAULT(1), CYAN_ONE_TIME_PASSWORD(2), CYAN_AUTHENTICATION_TOKEN(3).
   * @param X_CYAN_AUTHENTICATION_TOKEN Subscription owner identity authorisation token. The token is valid only in combination with X-CYAN-AUTHENTICATION-METHOD.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateProfileDefinitionUsingPUT(
    request: ProfileDefinitionInputDTO,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UpdateProfileDefinitionResult>;
  public updateProfileDefinitionUsingPUT(
    request: ProfileDefinitionInputDTO,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UpdateProfileDefinitionResult>>;
  public updateProfileDefinitionUsingPUT(
    request: ProfileDefinitionInputDTO,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UpdateProfileDefinitionResult>>;
  public updateProfileDefinitionUsingPUT(
    request: ProfileDefinitionInputDTO,
    X_CYAN_AUTHENTICATION_METHOD?: string,
    X_CYAN_AUTHENTICATION_TOKEN?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (request === null || request === undefined) {
      throw new Error(
        'Required parameter request was null or undefined when calling updateProfileDefinitionUsingPUT.'
      );
    }

    let headers = this.defaultHeaders;
    if (X_CYAN_AUTHENTICATION_METHOD !== undefined && X_CYAN_AUTHENTICATION_METHOD !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-METHOD', String(X_CYAN_AUTHENTICATION_METHOD));
    }
    if (X_CYAN_AUTHENTICATION_TOKEN !== undefined && X_CYAN_AUTHENTICATION_TOKEN !== null) {
      headers = headers.set('X-CYAN-AUTHENTICATION-TOKEN', String(X_CYAN_AUTHENTICATION_TOKEN));
    }

    // authentication (JWT) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
      headers = headers.set('Authorization', this.configuration.apiKeys['Authorization']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<UpdateProfileDefinitionResult>(
      `${this.basePath}/profile-definition`,
      request,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
