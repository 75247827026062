/**
 * Cloud-security
 * cloud-security RESTful API
 *
 * OpenAPI spec version: default
 * Contact: office@cyan.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { AuthenticateInternetschutzCustomerResult } from '../model/authenticateInternetschutzCustomerResult';
import { AuthenticationToken } from '../model/authenticationToken';
import { LogoutResult } from '../model/logoutResult';
import { TransparentDataWrapper } from '../model/transparentDataWrapper';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable()
export class AuthenticationService {
  protected basePath = 'https://10.36.200.206:9021/cms-api-gateway/cloudsecurity/api';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Authentication of mobileapp
   * Deprecated as it is not used by Selfcare-ui.
   * @param token token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public authenticateInternetschutzMobileAppUsingPOST(
    token: AuthenticationToken,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<AuthenticateInternetschutzCustomerResult>;
  public authenticateInternetschutzMobileAppUsingPOST(
    token: AuthenticationToken,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<AuthenticateInternetschutzCustomerResult>>;
  public authenticateInternetschutzMobileAppUsingPOST(
    token: AuthenticationToken,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<AuthenticateInternetschutzCustomerResult>>;
  public authenticateInternetschutzMobileAppUsingPOST(
    token: AuthenticationToken,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling authenticateInternetschutzMobileAppUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<AuthenticateInternetschutzCustomerResult>(
      `${this.basePath}/auth/mobile-app`,
      token,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Authentication of customer
   * It is not used by Selfcare-ui, but is still used internally by selfcare-launcher.
   * @param token token
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public authenticateInternetschutzWebUserUsingPOST(
    token: AuthenticationToken,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<AuthenticateInternetschutzCustomerResult>;
  public authenticateInternetschutzWebUserUsingPOST(
    token: AuthenticationToken,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<AuthenticateInternetschutzCustomerResult>>;
  public authenticateInternetschutzWebUserUsingPOST(
    token: AuthenticationToken,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<AuthenticateInternetschutzCustomerResult>>;
  public authenticateInternetschutzWebUserUsingPOST(
    token: AuthenticationToken,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (token === null || token === undefined) {
      throw new Error(
        'Required parameter token was null or undefined when calling authenticateInternetschutzWebUserUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<AuthenticateInternetschutzCustomerResult>(
      `${this.basePath}/auth/web-user`,
      token,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Logging out of customer
   *
   * @param transparentDataWrapper transparentDataWrapper
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public logoutWebUserUsingPOST(
    transparentDataWrapper: TransparentDataWrapper,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<LogoutResult>;
  public logoutWebUserUsingPOST(
    transparentDataWrapper: TransparentDataWrapper,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<LogoutResult>>;
  public logoutWebUserUsingPOST(
    transparentDataWrapper: TransparentDataWrapper,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<LogoutResult>>;
  public logoutWebUserUsingPOST(
    transparentDataWrapper: TransparentDataWrapper,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (transparentDataWrapper === null || transparentDataWrapper === undefined) {
      throw new Error(
        'Required parameter transparentDataWrapper was null or undefined when calling logoutWebUserUsingPOST.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['*/*'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<LogoutResult>(
      `${this.basePath}/auth/logout`,
      transparentDataWrapper,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
