import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdministrativeService } from './api/administrative.service';
import { ApplicationControllerService } from './api/applicationController.service';
import { AuthenticationService } from './api/authentication.service';
import { DeviceService } from './api/device.service';
import { DeviceProfileService } from './api/deviceProfile.service';
import { DomainExceptionService } from './api/domainException.service';
import { EmailIdentityService } from './api/emailIdentity.service';
import { LicenseService } from './api/license.service';
import { MobileAppBlockingService } from './api/mobileAppBlocking.service';
import { ProfileDefinitionService } from './api/profileDefinition.service';
import { SessionControllerService } from './api/sessionController.service';
import { SingleDeviceOldLayoutService } from './api/singleDeviceOldLayout.service';
import { StatisticsControllerService } from './api/statisticsController.service';
import { SubscriptionService } from './api/subscription.service';
import { URLService } from './api/uRL.service';
import { UnblockRequestsService } from './api/unblockRequests.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    AdministrativeService,
    ApplicationControllerService,
    AuthenticationService,
    DeviceService,
    DeviceProfileService,
    DomainExceptionService,
    EmailIdentityService,
    LicenseService,
    MobileAppBlockingService,
    ProfileDefinitionService,
    SessionControllerService,
    SingleDeviceOldLayoutService,
    StatisticsControllerService,
    SubscriptionService,
    URLService,
    UnblockRequestsService
  ]
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
