import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetDeviceOverviewStatisticsResult, StatisticsControllerService } from '../swagger-api';

@Injectable()
export class StatisticsService {
  constructor(private service: StatisticsControllerService) {}

  public getDeviceOverviewStatistics$(): Observable<GetDeviceOverviewStatisticsResult> {
    return this.service.getDeviceOverviewStatisticsUsingGET();
  }
}
